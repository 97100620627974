import React from 'react';
import { Grid } from '@material-ui/core';
import { formatPhoneNumber } from 'shared';
import { Colors, CopyIconButton, Text } from 'library';
import { styled } from '@mui/material';
import EditEmailButton from './EditEmailButton';
import EditPhoneNumberButton from './EditPhoneNumberButton';

export default function BusinessContact({
    userId,
    firstName,
    lastName,
    phoneNumber,
    email,
    communicationPreference,
    backgroundColor,
    refresh,
}: {
    userId: number;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email: string;
    communicationPreference: string;
    backgroundColor?: Colors;
    refresh: () => void;
}) {
    return (
        <Container
            item
            container
            direction="row"
            justify="space-between"
            xs={12}
            md={4}
            backgroundColor={backgroundColor}
        >
            <Grid item container direction="row" justify="space-between">
                <Text bold textStyle={{ maxWidth: '80%', overflow: 'auto' }}>
                    {firstName} {lastName}
                </Text>
            </Grid>
            {phoneNumber ? (
                <Text bold variant="caption">
                    Phone Number:{' '}
                    <Text inline variant="caption">
                        {formatPhoneNumber(phoneNumber)}{' '}
                    </Text>
                    <CopyIconButton tooltip="Copy Phone" textToCopy={formatPhoneNumber(phoneNumber) || ''} />
                    <EditPhoneNumberButton userId={userId} initialPhoneNumber={phoneNumber} onSave={refresh} />
                </Text>
            ) : null}
            {email ? (
                <Text bold variant="caption">
                    Email:{' '}
                    <Text variant="caption" inline>
                        {email}{' '}
                    </Text>
                    <CopyIconButton tooltip="Copy Phone" textToCopy={email} />
                    <EditEmailButton userId={userId} initialEmail={email} onSave={refresh} />
                </Text>
            ) : null}
            <Text bold variant="caption">
                Preferred Contact Method:{' '}
                <Text variant="caption" inline textStyle={{ textTransform: 'capitalize' }}>
                    {communicationPreference.toLowerCase()}
                </Text>
            </Text>
        </Container>
    );
}

const Container = styled(Grid)(({ backgroundColor }: { backgroundColor?: Colors }) => ({
    padding: 20,
    margin: 10,
    borderRadius: 18,
    backgroundColor: backgroundColor || Colors.dullTangerine,
}));
