import React, { useEffect, useState } from 'react';
import { BottomNavigation, BottomNavigationAction, Grid, withStyles } from '@material-ui/core';
import { Colors, Text } from 'library';
import { NavItem, updateNavigationByIndex } from './navigationItems';
import { useHistory, useLocation } from 'react-router-dom';
import BadgedNavItem from './BadgedNavItem';
import { getMessageCount } from './messageCount';
import usePublicSessionTracking, { PublicSessionEventType } from 'parent-portal/shared/PublicSessionTracking';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const NavigationItem = withStyles({
    root: {
        fontFamily: 'Work Sans',
        fontSize: 10,
        '&.Mui-selected': {
            color: Colors.darkNavy,
            outline: 'none',
            borderBottom: '2px solid',
            borderBottomColor: Colors.mediumTurq,
        },
    },
})(BottomNavigationAction);

export default function MobileNavigation({
    selectedPage,
    setSelectedPage,
    navigationItems,
}: {
    selectedPage: number | undefined;
    setSelectedPage: (selected: number) => void;
    navigationItems: NavItem[];
}) {
    const history = useHistory();
    const [unreadMessages, setUnreadMessages] = useState(0);
    const { trackSession } = usePublicSessionTracking('Top Navigation - Mobile');

    useEffect(() => {
        getMessageCount().then((count) => setUnreadMessages(count));
    }, []);

    const getIcon = (item: NavItem) => {
        return (
            <>
                {item.IconImg && <item.IconImg />}
                {item.SvgIconImg && <item.SvgIconImg />}
                {item.FontAwesomeImg && (
                    <FontAwesomeIcon icon={item.FontAwesomeImg} style={{ fontSize: 20, padding: 3 }} />
                )}
            </>
        );
    };
    const location = useLocation();
    const hideMobileNav = location.pathname.includes('messages') && location.pathname.split('/').length === 3;

    const handleNavigation = (event: any, newValue: number) => {
        const navName = updateNavigationByIndex(history, newValue, setSelectedPage, navigationItems);
        trackSession('CLICK', navName, PublicSessionEventType.User);
    };

    return hideMobileNav ? null : (
        <Grid
            style={{
                position: 'fixed',
                backgroundColor: Colors.white,
                bottom: 0,
                padding: 4,
                width: '100vw',
                zIndex: 999,
                boxShadow: '0px -3px 6px #00000029',
            }}
        >
            <BottomNavigation value={selectedPage} showLabels onChange={handleNavigation}>
                {navigationItems.map((item) => (
                    <NavigationItem
                        key={item.id}
                        label={
                            <>
                                <Grid style={{ flexDirection: 'row' }}>
                                    <Text variant="caption">{item.name}</Text>
                                </Grid>
                            </>
                        }
                        icon={
                            item.name === 'Messages' ? (
                                <BadgedNavItem count={unreadMessages} rightOffset="0%">
                                    {getIcon(item)}
                                </BadgedNavItem>
                            ) : (
                                getIcon(item)
                            )
                        }
                    />
                ))}
            </BottomNavigation>
        </Grid>
    );
}
