import React, { useEffect, useState } from 'react';
import { getPendingForms } from 'api/AdminDocumentApi';
import { Grid } from '@material-ui/core';
import { DocumentToVerify, DocumentTypeWithCount, PendingDocumentFilters } from './models';
import { DocumentDisplay } from './components/DocumentDisplay';
import HeaderActions from './components/HeaderActions';
import useLoading from 'library/hooks/useLoading';
import PaginatedList from 'internal/shared/PaginatedList';
import { PagingResult } from 'models';
import { useClient } from 'shared';

export default function VerifyDocuments({
    passedUserId,
    onRefresh,
}: {
    passedUserId?: number;
    onRefresh?: () => void;
}) {
    const [forms, setForms] = useState<PagingResult<DocumentToVerify>>();
    const [expanded, setExpanded] = useState<number>(-1);
    const [filters, setFilters] = useState<PendingDocumentFilters>({
        document_type_id: -1,
        page: 1,
    });
    const { loading, setLoading, LoadingSpinner } = useLoading();
    const { data: documentTypes } = useClient<DocumentTypeWithCount[]>({
        url: 'api/document-type/?include_count=true',
    });

    useEffect(search, []);

    function getData(filters: PendingDocumentFilters) {
        const userIdFilter = filters.user_id || passedUserId || undefined;
        const filtersToSend = getFiltersToSend({
            ...filters,
            user_id: userIdFilter,
        });
        setLoading(true);
        getPendingForms(filtersToSend)
            .then(setForms)
            .finally(() => setLoading(false));
        onRefresh?.();
    }

    function getFiltersToSend(filters: PendingDocumentFilters) {
        return Object.keys(filters).reduce(
            (acc: PendingDocumentFilters, key: string) => {
                const currentValue = filters[key as keyof PendingDocumentFilters];
                if (currentValue !== undefined && Number.isInteger(currentValue) && (currentValue as number) > 0) {
                    return { ...acc, [key]: currentValue };
                } else if (currentValue === true) {
                    return { ...acc, [key]: true };
                }
                return acc;
            },
            { page: 1 },
        ) as PendingDocumentFilters;
    }

    function search() {
        getData(filters);
    }

    function getFormsForDisplay() {
        return forms?.results.map((form: DocumentToVerify) => {
            if (form.name === 'Character Reference Document') {
                const reference_doc_number = parseInt(form.title?.split('_')[2] || '');
                const modifiedName = Number.isInteger(reference_doc_number)
                    ? `${form.name} ${reference_doc_number}`
                    : form.name;
                return { ...form, name: modifiedName };
            }
            return form;
        });
    }

    function handlePageChange(p: number) {
        const newFilters = { ...filters, page: p + 1 };
        setFilters(newFilters);
        getData(newFilters);
    }

    if (!forms) {
        return <LoadingSpinner />;
    }

    const renderHeaderActions = !passedUserId && documentTypes;

    return (
        <Grid container direction="column" style={{ padding: passedUserId ? 10 : 30 }}>
            {renderHeaderActions && (
                <HeaderActions
                    documentTypes={documentTypes}
                    getData={getData}
                    filters={filters}
                    setFilters={setFilters}
                    search={search}
                />
            )}
            {loading ? <LoadingSpinner /> : <></>}

            <PaginatedList data={forms} page={filters?.page || 1} updatePage={handlePageChange}>
                {getFormsForDisplay()?.map((form: DocumentToVerify) => (
                    <DocumentDisplay
                        form={form}
                        key={form.id}
                        refresh={search}
                        expanded={expanded}
                        setExpanded={setExpanded}
                    />
                ))}
            </PaginatedList>
        </Grid>
    );
}
