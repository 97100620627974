import { JobDetailValueWithOption, JobQualificationValue } from 'models';
import moment from 'moment';

export interface JobOptions {
    paymentSchedule: PaymentSchedule | undefined;
    daysOfTheWeek: DayOfWeekSelections;
    selectedPreferredWorkers: ProviderSelection[];
    selectedPastWorkers: ProviderSelection[];
    faqs: JobFaq[];
    [key: string]: any;
}

export interface DayOfWeek {
    selected: boolean;
    start: moment.Moment | null;
    end: moment.Moment | null;
    slots: number;
    day: number;
}

export interface WorkingHoursForDay {
    [key: string]: {
        start: string;
        end: string;
        timezone: string;
        slots: number;
        day?: number;
    };
}

export interface DayOfWeekMapping<ValueType> {
    Sunday: ValueType;
    Monday: ValueType;
    Tuesday: ValueType;
    Wednesday: ValueType;
    Thursday: ValueType;
    Friday: ValueType;
    Saturday: ValueType;
}

export type DayOfWeekSelections = DayOfWeekMapping<DayOfWeek>;

export interface PaymentSchedule {
    payDay: 'SUNDAY' | 'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY' | 'SUNDAY';
    payWindow: PayWindow;
}

export type PayWindow = 'DAILY' | 'AUTOMATIC' | 'WEEKLY';

export interface RequestableProvider {
    id: number;
    name: string;
}

export interface ProviderSelection {
    name: string;
    id: number;
}

export type ProviderType = 'sitter' | 'tutor' | 'teacher' | 'nanny';

export type ProviderTypeSelection = {
    [Type in ProviderType]: boolean;
};

export type RequestType = '' | 'onetime' | 'recurring';

export enum TemplateOption {
    NEW = 'new',
    TEMPLATE = 'template',
}

export interface JobFaq {
    question: {
        id: number;
    };
    answer: string;
}

export type StartEstimateMonthType = string | null;

export interface IPayFormFields {
    payMin: number;
    payMax: number;
    payFixed: number | null;
    isFixedPay: boolean | null;
}

interface OngoingFormFields {
    startEstimateMonth: StartEstimateMonthType;
    daysOfTheWeek: DayOfWeekSelections;
    sameTimes: boolean;
    longTermJob: boolean;
    multipleProviders: boolean;
    paymentSchedule: PaymentSchedule | undefined;
}

interface OneTimeFormFields {
    jobTimes: JobTimes;
    isQuickFill: boolean | null;
}

export interface JobTimes {
    start: moment.Moment | null;
    end: moment.Moment | null;
    slots: number;
    day?: number;
}

export interface IPayScaleQualification {
    value: string | null;
    option_id: number | null;
    qualification_id: number;
    name: string;
}

export interface ExtendedPayScaleQualification extends IPayScaleQualification {
    id: number;
    option: string | null;
}

export interface IPayScale {
    rate: number;
    description: string;
    qualifications: IPayScaleQualification[];
}

export interface ExtendedPayScale extends IPayScale {
    id: number;
    qualifications: ExtendedPayScaleQualification[];
}

export interface JobLocation {
    id: number;
    businessLocationId?: number;
}

interface CommonFormFields {
    startDate: moment.Moment | null;
    endDate: moment.Moment | null;
    lastHireOffsetMinutes: number;
    transportation: boolean | null;
    preferredWorkers: ProviderSelection[];
    pastWorkers: ProviderSelection[];
    comments: string;
    headline: string;
    pay: IPayFormFields;
    requestType: RequestType;
    jobTypeId: number;
    otherJobType: string;
    userEmail: string;
    zipCode: string;
    acceptedTandemPayAgreement: boolean | undefined;
    code: string;
    payType: JobPaymentTypes | undefined;
    businessJobType: BusinessJobTypes | undefined;
    jobQualifications: JobQualificationValue[];
    address: JobLocation[];
    jobDetails: JobDetailValueWithOption[];
    payScales: IPayScale[];
    breakRequired: boolean;
    breakLength: number | undefined;
    slotsAvailable: number;
    useTemplate: TemplateOption;
    templateJobId: number | undefined;
    trialRunCoverage: boolean;
    faqs: JobFaq[];
    requireMinimumQualifications: boolean;
    addMinimumBreak: boolean;
    breakEveryNHours: number | null;
    trialRunBenefits: string | undefined;
}

export interface PostBodyType {
    family: number;
    start_date: string | null;
    end_date: string | null;
    last_hire_offset_minutes: number;
    pay: number | null;
    rate_min: number | null;
    rate_max: number | null;
    transportation: boolean;
    multiple_providers: false;
    status: string;
    quick_fill: boolean;
    family_comments: string;
    days_of_week: string[] | null;
    preferred_sitters: {
        preferred_sitters: {
            sitter: number;
        }[];
    };
    times_of_day: WorkingHoursForDay[];
    pay_window: string;
    start_month: any | null;
    ongoing: boolean;
    headline: string;
    request_type: string;
    job_type: number;
    pay_type: JobPaymentTypes;
    business_job_type: BusinessJobTypes;
    pay_range_info: string;
    pay_scales: IPayScale[];
    break_every_n_minutes?: number;
}
export type JobPaymentTypes = 'PERCENTAGE' | null;
export type BusinessJobTypes = 'TRIAL_RUN' | 'SUBSTITUTE' | null;

export interface JobFormFields extends CommonFormFields, OneTimeFormFields, OngoingFormFields {}
