import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import DashboardSection from './DashboardSection';
import { Colors, Text } from 'library';

export default function HowItWorks() {
    const classes = useStyles();

    return (
        <DashboardSection header="How it Works">
            <Grid>
                <Stepper alternativeLabel nonLinear>
                    <Step active>
                        <StepLabel classes={{ label: classes.label }} StepIconProps={{ style: { color: '#F490AC' } }}>
                            Post a shift on the Tandem site.
                        </StepLabel>
                    </Step>
                    <Step active>
                        <StepLabel
                            classes={{ label: classes.label }}
                            StepIconProps={{ style: { color: Colors.mediumTurq } }}
                        >
                            You will be notified when Tandem confirms a worker.
                        </StepLabel>
                    </Step>
                    <Step active>
                        <StepLabel
                            classes={{ label: classes.label }}
                            StepIconProps={{ style: { color: Colors.mediumTerraCotta } }}
                        >
                            Confirmed worker will work shift. Download compliance on their profile.
                        </StepLabel>
                    </Step>
                    <Step active>
                        <StepLabel
                            classes={{ label: classes.label }}
                            StepIconProps={{ style: { color: Colors.turquoise } }}
                        >
                            Workers wages &amp; associated fees will be charged on Sunday evenings.
                        </StepLabel>
                    </Step>
                </Stepper>

                <Text variant="h2" bold={true}>
                    The Tandem Process
                </Text>
                <Grid container alignContent="center" className={classes.howItem}>
                    <Grid container item justify="center" xs={1}>
                        <ArrowCircleRightIcon htmlColor="#F490AC" />
                    </Grid>
                    <Grid item xs={11}>
                        <Text variant="body2">
                            We credential all workers to <b>meet state requirements.</b>
                        </Text>
                    </Grid>
                </Grid>
                <Grid container alignContent="center" className={classes.howItem}>
                    <Grid container item justify="center" xs={1}>
                        <ArrowCircleRightIcon htmlColor={Colors.mediumTurq} />
                    </Grid>
                    <Grid item xs={11}>
                        <Text variant="body2">
                            We use your shift information to find worker(s) who{' '}
                            <b>fulfill your preferences and requirements.</b>
                        </Text>
                    </Grid>
                </Grid>
                <Grid container alignContent="center" className={classes.howBottomItem}>
                    <Grid container item justify="center" xs={1}>
                        <ArrowCircleRightIcon htmlColor={Colors.mediumTerraCotta} />
                    </Grid>
                    <Grid item xs={11}>
                        <Text variant="body2">
                            We <b>evaluate reliability and fit</b> on a daily basis. We're working to find you quality
                            workers, while also providing you coverage as quickly as we can.
                        </Text>
                    </Grid>
                </Grid>
            </Grid>
        </DashboardSection>
    );
}

const useStyles = makeStyles(() => ({
    howItem: {
        padding: '10px 5px',
    },
    howBottomItem: {
        padding: 5,
        marginBottom: 10,
    },
    label: {
        color: `${Colors.darkNavy} !important`,
        fontFamily: 'Work Sans !important',
    },
}));
