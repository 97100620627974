import React, { useEffect, useState } from 'react';
import { Provider, Job } from 'models';
import { client } from 'shared';
import MessageForm from './MessageForm';
import MessageSent from './MessageSent';

interface IProps {
    onClose: (sent?: boolean) => void;
    providers: Provider[];
    job?: Job;
    from: string;
    requested?: boolean;
    sentButtonText?: string;
    location?: string;
}

export default function InviteForm({
    onClose,
    providers,
    job,
    from,
    sentButtonText = 'Close',
    location = 'undefined',
}: IProps) {
    const [showSentScreen, setSentScreen] = useState(false);
    const [loading, setLoading] = useState(true);
    const provider = providers.length === 1 ? providers[0] : undefined;

    useEffect(() => {
        if (provider) {
            setLoading(true);
            isProviderInvited(provider, job!)
                .then((providerAlreadyInvited) => {
                    setSentScreen(providerAlreadyInvited);
                })
                .finally(() => setLoading(false));
        }
        if (providers.length > 1) {
            setLoading(false);
        }
    }, [providers, job, provider, from]);

    return (
        <div style={{ opacity: loading ? 0 : 1 }}>
            {showSentScreen ? (
                <MessageSent
                    providers={providers}
                    onClose={() => onClose(showSentScreen)}
                    sentButtonText={sentButtonText}
                />
            ) : (
                <MessageForm providers={providers} job={job} setSentScreen={setSentScreen} location={location} />
            )}
        </div>
    );
}

function isProviderInvited(provider: Provider, job: Job) {
    return client(`api/invitation/check-if-invitation-exists/?invited_user=${provider.user.id}&job_request=${job!.id}`);
}
