import React, { useEffect, useState } from 'react';
import { Text, TextButton, Colors, PrimaryButton, CloseButton } from 'library';
import { AdminProvider, IInfoModalDoc } from 'models';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import VerifyModal from 'internal/document-verify/VerifyModal';
import DeleteModal from 'internal/document-verify/DeleteModal';
import VerifyDocuments from 'internal/document-verify/VerifyDocuments';
import { getLink } from 'parent-portal/provider-profile/tabs/documents/DocumentShared';
import { getLinks } from './buildLinks';
import AllDocumentActions from './AllDocumentActions';
import { getRequiredDocumentInfo } from 'api/AdminDocumentApi';
import { Link } from 'react-router-dom';

export default function DocumentsSection({
    provider,
    refreshProvider,
}: {
    provider: AdminProvider;
    refreshProvider: () => void;
}) {
    const [verifyModalOpen, setVerifyModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [active, setActive] = useState<IInfoModalDoc | undefined>(undefined);
    const [needsDocumentInfo, setNeedsDocumentInfo] = useState(false);
    const links: IInfoModalDoc[] = getLinks(provider);

    function checkForInfoNeeded() {
        if (provider.user.compliance_state !== 'Illinois') return;
        getRequiredDocumentInfo(provider.user.id).then((res) => {
            setNeedsDocumentInfo(res && res.missing_required_fields);
        });
    }

    useEffect(checkForInfoNeeded, [provider]);

    const documentInfoText = needsDocumentInfo ? 'Needs Document Info Collected' : 'Update Document Info';

    return (
        <>
            <Grid container item direction="row" alignItems="center" justify="space-between" style={{ marginTop: 10 }}>
                <Text variant="h2" bold textStyle={{ color: Colors.turquoise }}>
                    Documents
                </Text>
                <Link
                    title={documentInfoText}
                    style={{
                        color: needsDocumentInfo ? Colors.red : Colors.darkNavy,
                        textDecorationLine: 'underline',
                        fontSize: 14,
                    }}
                    to={`/adminperson/compliance/collect-document-info/${provider.user.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {documentInfoText}
                </Link>
                <Grid item container xs={12} md={8}>
                    <AllDocumentActions provider={provider} />
                </Grid>
            </Grid>

            <VerifyDocuments passedUserId={provider.user.id} onRefresh={refreshProvider} />

            <ul>
                {links.map((x) => (
                    <Grid key={x.id} container item>
                        <Grid container item xs={5} style={{ gap: 5 }}>
                            <li key={x.url}>{getLink({ type: x.type, url: x.url, text: x.text, id: x.id })}</li>
                            {x.added_at ? (
                                <Text variant="caption">Added at: {moment(x.added_at).format('MMM DD, YYYY')}</Text>
                            ) : null}
                            {x.verified_at ? (
                                <Text variant="caption">
                                    {' '}
                                    Verified at: {moment(x.verified_at).format('MMM DD, YYYY')}
                                </Text>
                            ) : null}
                            {x.ignored_at ? (
                                <Text variant="caption">
                                    {' '}
                                    Ignored at: {moment(x.ignored_at).format('MMM DD, YYYY')}
                                </Text>
                            ) : null}
                        </Grid>
                        {x.text !== 'FBI/BCI Background Check' && x.text !== 'Form 718b' && !x.verified_at ? (
                            <Grid container item xs={6}>
                                <TextButton
                                    onClick={() => {
                                        setVerifyModalOpen(true);
                                        setActive(x);
                                    }}
                                >
                                    Verify
                                </TextButton>
                            </Grid>
                        ) : null}
                        {x.text !== 'Form 718b' ? (
                            <Grid container item xs={1}>
                                <CloseButton
                                    onClose={() => {
                                        setDeleteModalOpen(true);
                                        setActive(x);
                                    }}
                                ></CloseButton>
                            </Grid>
                        ) : null}

                        <VerifyModal
                            verifyModalOpen={verifyModalOpen}
                            setVerifyModalOpen={setVerifyModalOpen}
                            form={active}
                            activeWorker={provider.user.id}
                        />
                        <DeleteModal
                            deleteModalOpen={deleteModalOpen}
                            setDeleteModalOpen={setDeleteModalOpen}
                            form={active}
                        />
                    </Grid>
                ))}
            </ul>
            <Grid container item xs={6} style={{ margin: 20 }}>
                <PrimaryButton
                    onClick={() => window.open(`/adminperson/upload-documents/${provider.user.id}`, '_blank')}
                >
                    Upload Documents
                </PrimaryButton>
            </Grid>
        </>
    );
}
