import { Grid } from '@material-ui/core';
import React from 'react';
import { TextButton } from 'library';
import { format12HourTime } from 'shared/Dates';
import CardInfo from './CardInfo';
import { useHistory } from 'react-router-dom';
import { getHourReportStatus } from 'parent-portal/subs/HourReportMethods';
import { IHourReport } from 'internal/payments/HourReportModels';

export default function HourReportInfo({ hourReport }: { hourReport: IHourReport }) {
    const history = useHistory();

    const hourReportStatus = getHourReportStatus(hourReport);

    return (
        <>
            {hourReport.start ? (
                <CardInfo title="Clocked In:" content={format12HourTime(hourReport.start)} />
            ) : hourReport.late_minutes ? (
                <CardInfo title="Reported Late" content={`${hourReport.late_minutes} minutes`} />
            ) : null}
            {hourReport.end ? <CardInfo title="Clocked Out:" content={format12HourTime(hourReport.end)} /> : null}
            {hourReport.approved_break ? (
                <CardInfo title="Break:" content={`${hourReport.approved_break} minutes`} />
            ) : null}
            {hourReport.hours_worked ? (
                <CardInfo title="Hours Worked:" content={hourReport.hours_worked.toString()} />
            ) : null}
            {hourReportStatus ? <CardInfo title="Status:" content={getHourReportStatus(hourReport)} /> : null}
            {hourReport.start && hourReport.end ? (
                <CardInfo title="Payment Status:" content={hourReport.paid ? 'Paid' : 'Pending'} />
            ) : null}
            {hourReport.start && hourReport.end && !hourReport.paid ? (
                <Grid item container direction="column" style={{ marginTop: 5, gap: 5 }} alignItems="center">
                    <TextButton onClick={() => history.push('confirm-hours')}>Manage Hours</TextButton>
                </Grid>
            ) : null}
        </>
    );
}
