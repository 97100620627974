import React, { useEffect, useState } from 'react';
import { PrimaryButton, SecondaryButton } from 'library';
import { Snackbar } from '@mui/material';
import { useLocation } from 'react-router';
import { stopImpersonating } from 'shared/impersonation';
import useWindowFocus from 'shared/WindowFocus';

export default function ImpersonationButton() {
    const pathname = useLocation().pathname;
    const [snackOpen, setSnackOpen] = useState(false);
    const [impersonating, setImpersonating] = useState(false);

    function stop() {
        stopImpersonating();
        window.close();
    }

    function onFocus() {
        if (impersonating && !localStorage.getItem('old')) {
            setImpersonating(false);
            setSnackOpen(true);
        }
    }

    useWindowFocus(onFocus, [impersonating]);

    useEffect(() => {
        if (localStorage.getItem('old')) setImpersonating(true);
    }, [pathname]);

    return (
        <>
            {impersonating ? <SecondaryButton onClick={stop}>Stop Impersonating</SecondaryButton> : null}
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={snackOpen}
                onClose={() => setSnackOpen(false)}
                message="It looks like you're no longer impersonating in this tab. Would you like to close it?"
                action={<PrimaryButton onClick={window.close}>Close</PrimaryButton>}
            />
        </>
    );
}
