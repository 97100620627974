import React, { useState } from 'react';
import { IconButton, PrimaryButton, SecondaryButton, Text, TextButton } from 'library';
import { SIGNED_MED_FORM, ignoreForm } from 'api/AdminDocumentApi';
import { Grid } from '@material-ui/core';
import VerifyModal from '../VerifyModal';
import ProviderDetailsOutreachModal from 'internal/texting/outreach/ProviderDetailsOutreachModal';
import { DocumentToVerify, FormDetails } from '../models';
import DeleteModal from '../DeleteModal';
import CloseIcon from '@material-ui/icons/Close';
import { downloadFile } from 'parent-portal/provider-profile/tabs/documents/DocumentShared';
import { client } from 'shared';
import { isAfter } from 'date-fns';

export function DocumentHeader({
    form,
    refresh,
    formDetails,
}: {
    form: DocumentToVerify;
    refresh: () => void;
    formDetails: FormDetails;
}) {
    const [verifyModalOpen, setVerifyModalOpen] = useState(false);
    const [detailsOpen, setDetailsOpen] = useState(false);
    const showMedVersion = form.name.includes(SIGNED_MED_FORM);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const urlStart = 'api/documents';

    function adminDownload() {
        downloadFile(`api/documents/${form.id}/download/`);
    }

    function snoozeDocument() {
        client(`${urlStart}/${form.id}/snooze/`, { method: 'POST' }).then(refresh);
    }

    const formSnoozed = form.snoozed_until ? isAfter(new Date(form.snoozed_until), new Date()) : false;

    return (
        <Grid container direction="row" item style={{ gap: 10, alignItems: 'center', justifyContent: 'space-between' }}>
            <Grid item container xs={6} style={{ gap: 10 }}>
                <Grid container item xs={6} direction="column">
                    <Text variant="body1" bold>
                        {`${form.name} - `}
                        <TextButton
                            buttonStyle={{ width: undefined, marginRight: 10 }}
                            onClick={(e) => {
                                e.stopPropagation();
                                setDetailsOpen(true);
                            }}
                        >
                            {form.first_name} {form.last_name} ({form.user_id}) - {form.state}
                        </TextButton>
                    </Text>
                    <Text variant="caption">Added at: {form.added_at}</Text>
                    {formSnoozed ? <Text variant="caption">Snoozed until: {form.snoozed_until}</Text> : null}
                    <TextButton onClick={adminDownload} buttonStyle={{ width: 'unset' }}>
                        Download Document
                    </TextButton>
                    <TextButton onClick={snoozeDocument} buttonStyle={{ width: 'unset' }}>
                        Snooze Document
                    </TextButton>
                </Grid>
            </Grid>
            <Grid container item direction="row" alignItems="center" justify="flex-end" style={{ gap: 20 }} xs={5}>
                <PrimaryButton
                    disabled={!showMedVersion && !formDetails?.document_type_id}
                    onClick={(e) => {
                        e.stopPropagation();
                        setVerifyModalOpen(true);
                    }}
                    buttonStyle={{ maxWidth: 150, maxHeight: 50 }}
                >
                    Verify
                </PrimaryButton>
                <SecondaryButton
                    onClick={(e) => {
                        e.stopPropagation();
                        ignoreForm(form?.id, form?.name, refresh);
                    }}
                    buttonStyle={{ maxWidth: 150, maxHeight: 50 }}
                >
                    Ignore
                </SecondaryButton>
                <IconButton
                    onClick={(e) => {
                        e.stopPropagation();
                        setDeleteModalOpen(true);
                    }}
                    icon={CloseIcon}
                />
            </Grid>
            <div onClick={(e) => e.stopPropagation()}>
                <VerifyModal
                    verifyModalOpen={verifyModalOpen}
                    setVerifyModalOpen={setVerifyModalOpen}
                    form={form}
                    formDetails={formDetails}
                    refresh={refresh}
                    activeWorker={form.user_id}
                />
                <ProviderDetailsOutreachModal
                    providerUserId={form.user_id}
                    onClose={() => {
                        setDetailsOpen(false);
                    }}
                    open={detailsOpen}
                />
                <DeleteModal
                    deleteModalOpen={deleteModalOpen}
                    setDeleteModalOpen={setDeleteModalOpen}
                    form={form}
                    refresh={refresh}
                />
            </div>
        </Grid>
    );
}
