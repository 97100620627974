import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import LoginLine from '../../reusableComponents/line';
import Input from '../../reusableComponents/input';
import { isValidEmail } from 'shared/Validators';
import { client, consoleLogInDev } from 'shared';

export default function EmailVerificationInput({
    email,
    setEmail,
    setEmailValid,
}: {
    email: string;
    setEmail: (email: string) => void;
    setEmailValid: (valid: boolean) => void;
}) {
    const [emailExists, setEmailExists] = useState(false);
    const [emailVerified, setEmailVerified] = useState(false);

    function checkEmailExists() {
        client(`api/users/validate-email/?email=${email}`)
            .then((result) => {
                setEmailExists(result.exists);
                setEmailValid(isValidEmail(email) && result.exists);
                setEmailVerified(true);
            })
            .catch(consoleLogInDev);
    }

    function onValueChanged(event: any) {
        setEmailVerified(false);
        setEmail(event.target.value);
        setEmailValid(isValidEmail(event.target.value) && emailVerified && emailExists);
    }

    function getHelperText() {
        if (!email) return '';

        return !isValidEmail(email)
            ? 'Please enter a valid email address'
            : emailVerified && !emailExists
            ? 'An account with this email address does not exist. Sign up now!'
            : '';
    }

    return (
        <Grid>
            <LoginLine>
                <Input
                    error={!!email && (!isValidEmail(email) || (emailVerified && !emailExists))}
                    helperText={getHelperText()}
                    fullWidth
                    label="Email"
                    variant="outlined"
                    value={email}
                    onChange={onValueChanged}
                    onBlur={checkEmailExists}
                />
            </LoginLine>
        </Grid>
    );
}
