import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import client from 'shared/ApiClient';
import { DropDown, Text, LoadingIndicator, TextButton } from 'library';
import { Colors } from '../../../constants';
import { consoleLogInDev } from '../../../shared/Utils';
import OutreachConversation from './OutreachConversation';
import OutreachThreadCards from './OutreachThreadCards';
import { IMessageThread, TandemNumberKeyValuePairs } from './OutreachModels';
import OutreachSearch from './OutreachSearch';
import Labels from '../../shared/tables/Labels';
import { AdminProvider, PagingResult } from 'models';
import NotesModal from 'internal/shared/tables/NotesModal';
import NewFilters from './NewFilters';
import { isMobile } from 'react-device-detect';

import { IAdminMedicalRecord } from 'models/Providers/MedicalRecord';
import { useHistory, useLocation } from 'react-router';
import ComplianceProgress from './components/ComplianceProgress/ComplianceProgress';

export const TANDEM_FROM_NUMBERS: TandemNumberKeyValuePairs[] = [
    { key: '+16144123264', value: 'Provider Success Funnel (614) 412-3264' },
    { key: '+16146183792', value: 'Provider Outreach (614) 618-3792' },
    { key: '+16146420112', value: 'New Provider Outreach (614) 642-0112' },
    { key: '+16146393291', value: 'BCI/FBI Appointment Automation (614) 639-3291' },
    { key: '+16143495661', value: "Olivia's GV" },
    { key: '+16143448887', value: "McKenzie's GV" },
    { key: '+16142854739', value: "Natalie's GV" },
];

interface IFilterSearch {
    providerLabels: number[];
    excludedProviderLabels: number[];
    state?: string[];
    regions?: number[];
    newToChildcare?: string;
}

const NewOutreachMessages = () => {
    const [phoneNumber, setPhoneNumber] = useState<string>(() => TANDEM_FROM_NUMBERS[0].key);
    const [displayName, setDisplayName] = useState<string>('');
    const [threads, setThreads] = useState<PagingResult<IMessageThread>>();
    const [selectedNumber, setSelectedNumber] = useState<string>('');
    const [selectedThread, setSelectedThread] = useState<IMessageThread>();
    const [provider, setProvider] = useState<AdminProvider>();
    const [pageNumber, setPageNumber] = useState(1);
    const [search, setSearch] = useState<string>('');
    const [filters, setFilters] = useState<IFilterSearch>();
    const [loading, setLoading] = useState(false);
    const [showFiltersModal, setShowFiltersModal] = useState<boolean>(false);
    const [region, setRegion] = useState<string>('');
    const { search: urlSearch, pathname } = useLocation();
    const history = useHistory();

    function updateSearchParams(searchString?: string, threadId?: number) {
        const searchParams = searchString ? `search=${searchString}` : '';
        const threadParams = threadId ? `thread=${threadId}` : '';
        const newSearch = `${searchParams}${searchParams && threadParams ? '&' : ''}${threadParams}`;
        history.replace({ pathname, search: newSearch ? `?${newSearch}` : '' });
    }

    useEffect(() => {
        const params = new URLSearchParams(urlSearch);
        const passedSearch = params.get('search');
        const threadId = params.get('thread');
        if (passedSearch) {
            setSearch(passedSearch);
            refreshThreads(1, passedSearch, undefined, threadId ? parseInt(threadId) : undefined);
        } else {
            refreshThreads(1);
        }
    }, [phoneNumber]);

    const refreshThreads = (
        pageNumber: number,
        searchString?: string,
        filterObj?: IFilterSearch,
        threadIdSelectedAfterSearch?: number,
    ) => {
        const params = new URLSearchParams({
            phone_number: phoneNumber,
            page: pageNumber.toString(),
            search: searchString || '',
            plabels: filterObj?.providerLabels.join(',') || '',
            eplabels: filterObj?.excludedProviderLabels.join(',') || '',
            state: filterObj?.state?.join(',') || '',
            regions: filterObj?.regions?.join(',') || '',
            new_to_childcare: filterObj?.newToChildcare || '',
        });

        const url = `tracking/api/external-message-thread/?${params.toString()}`;
        setLoading(true);
        client(url)
            .then((response: PagingResult<IMessageThread>) => {
                setThreads(response);
                setPageNumber(pageNumber);
                if (threadIdSelectedAfterSearch) {
                    const thread = response.results.find((t) => t.id === threadIdSelectedAfterSearch);
                    if (thread) {
                        updateDisplayFromThread(thread);
                    }
                } else {
                    setSelectedNumber('');
                    setSelectedThread(undefined);
                }
            })
            .catch(consoleLogInDev)
            .finally(() => setLoading(false));
    };

    const handleChange = (newPhoneNumber: string) => {
        setPhoneNumber(newPhoneNumber);
    };

    function updateDisplayFromThread(thread: IMessageThread) {
        const clickPhoneNumber = thread?.external_phone_number;
        setSelectedNumber(clickPhoneNumber);

        const curThread = thread;
        const firstName = curThread?.user_first_name
            ? curThread?.user_first_name
            : curThread?.first_name
              ? curThread?.first_name
              : curThread?.user?.first_name ?? null;
        const lastName = curThread?.user_last_name
            ? curThread?.user_last_name
            : curThread?.last_name
              ? curThread?.last_name
              : curThread?.user?.last_name ?? null;
        setSelectedThread(thread);

        if (curThread) {
            curThread.unread_count = 0;
            setDisplayName(`${firstName ? firstName : ''} ${lastName ? lastName : ''}`.trim());
            const state = curThread?.state ? curThread.state : 'None';
            const region = curThread.user?.region;
            const regionOrState = region && region !== 'Ohio' ? region : state;
            setRegion(regionOrState);
        }
        updateProvider(curThread?.user?.id);
        return curThread;
    }

    const handleClick = (thread: IMessageThread, index: number) => {
        const curThread = updateDisplayFromThread(thread);

        setThreads((currValue) => {
            if (!currValue?.results) {
                return undefined;
            }

            return {
                ...currValue,
                results: [
                    ...currValue.results.slice(0, index),
                    { ...currValue.results[index as number], ...curThread },
                    ...currValue.results.slice(index + 1),
                ],
            };
        });
    };

    const handleSearch = (clearSearch = false) => {
        if (clearSearch) {
            setSearch('');
            updateSearchParams('');
            refreshThreads(1);
        } else {
            updateSearchParams(search);
            refreshThreads(1, search, filters);
        }
    };

    const onFilterSearch = (searchObj: IFilterSearch) => {
        setShowFiltersModal(false);
        setFilters(searchObj);
        refreshThreads(1, '', searchObj);
    };

    const updateProvider = (userId: number | undefined) => {
        if (!userId) {
            return;
        }

        client(`api/admin-providers/${userId}/`).then(setProvider);
    };

    return (
        <Grid container style={{ paddingTop: '2%' }}>
            <Grid container>
                <Grid item container direction="row" justify="space-between" alignItems="center" xs={12}>
                    <Grid item style={{ width: 350 }}>
                        <DropDown
                            title="Tandem Phone Number"
                            placeholder="All"
                            selected={phoneNumber}
                            fields={TANDEM_FROM_NUMBERS}
                            onChange={(e) => handleChange(e.target.value)}
                        />
                        {loading && <LoadingIndicator />}
                    </Grid>
                    {selectedNumber !== '' ? (
                        <Grid item container justify="flex-end" xs={6}>
                            <Text variant="body2">New messages in yellow</Text>
                        </Grid>
                    ) : null}
                </Grid>
                <Grid item>
                    <OutreachSearch
                        onSearch={handleSearch}
                        setSearch={setSearch}
                        search={search}
                        senderPhone={phoneNumber}
                        refreshThreads={() => {
                            setPageNumber(1);
                            refreshThreads(1);
                        }}
                        setSelectedNumber={setSelectedNumber}
                        selectedNumber={selectedNumber}
                    />
                </Grid>
                <Grid>
                    <TextButton onClick={() => setShowFiltersModal(true)}>Filters</TextButton>
                    <NewFilters
                        open={showFiltersModal}
                        onClose={() => setShowFiltersModal(false)}
                        onSearch={onFilterSearch}
                    />
                </Grid>
                <Grid item container direction="row">
                    <Grid item container xs={3}>
                        <Grid
                            item
                            container
                            direction="column"
                            xs={12}
                            style={{
                                padding: 10,
                                backgroundColor: Colors.mediumTurq,
                                borderRadius: 5,
                                overflow: 'scroll',
                                flexWrap: 'nowrap',
                                maxHeight: '80vh',
                            }}
                        >
                            <OutreachThreadCards
                                threads={threads}
                                handleClick={handleClick}
                                selectedNumber={selectedNumber}
                            />
                        </Grid>
                        <Pagination
                            count={threads?.num_pages}
                            page={pageNumber}
                            onChange={(event, page) => {
                                setPageNumber(page);
                                refreshThreads(page, search, filters);
                            }}
                            siblingCount={2}
                            boundaryCount={2}
                        />
                    </Grid>
                    <Grid item container xs={9} justify="center">
                        <Grid container item>
                            <Grid container item xs={12}></Grid>
                            {selectedNumber ? (
                                <Grid container item xs={12}>
                                    <Grid item xs={12} md={8}>
                                        <OutreachConversation
                                            externalNumber={selectedNumber}
                                            tandemNumber={phoneNumber}
                                            displayName={displayName}
                                            region={region}
                                            userId={selectedThread?.user_id}
                                            thread_id={selectedThread?.id}
                                            medStatus={
                                                provider?.medical_record &&
                                                !!provider?.medical_record?.filter(
                                                    (med: IAdminMedicalRecord) => med.med_flow_status,
                                                ).length
                                                    ? provider?.medical_record?.filter(
                                                          (med: IAdminMedicalRecord) => med.med_flow_status,
                                                      )[0].med_flow_status
                                                    : undefined
                                            }
                                            onMarkUnread={() => {
                                                setSelectedNumber('');
                                            }}
                                        />
                                    </Grid>
                                    {!isMobile ? (
                                        <Grid
                                            container
                                            item
                                            xs={2}
                                            md={2}
                                            alignItems="flex-start"
                                            style={{ display: 'inline' }}
                                        >
                                            <Grid container item xs={12}>
                                                <Grid item xs={12} style={{ marginBottom: '2%' }}>
                                                    <Text variant="body2" bold>
                                                        Thread Labels
                                                    </Text>
                                                </Grid>
                                                <Labels type={'external-message-thread'} object={selectedThread} />
                                            </Grid>
                                            {selectedThread && selectedThread?.babysitter_id ? (
                                                <Grid container item xs={12} style={{ marginTop: '10%' }}>
                                                    <ComplianceProgress
                                                        key="ComplianceProgress"
                                                        providerUserId={selectedThread.user?.id}
                                                    />
                                                    <Grid item xs={12} style={{ marginBottom: '2%' }}>
                                                        <Text variant="body2" bold>
                                                            Provider Labels
                                                        </Text>
                                                    </Grid>
                                                    <Labels
                                                        type={'admin-providers'}
                                                        object={{ id: selectedThread?.babysitter_id }}
                                                    />
                                                </Grid>
                                            ) : null}
                                            {provider ? (
                                                <NotesModal type="admin-providers" objectId={provider.id} />
                                            ) : null}
                                        </Grid>
                                    ) : null}
                                </Grid>
                            ) : null}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default NewOutreachMessages;
