import React, { useState } from 'react';
import { Grid, useMediaQuery } from '@material-ui/core';
import { client, consoleLogInDev, formatPhoneNumber } from 'shared';
import { Colors, IconButton, SizeableRoundedDialog, Text } from 'library';
import theme from 'theme';
import ClearIcon from '@mui/icons-material/Clear';
import EditExistingContact from './EditExistingContact';
import { IBusinessContact } from 'models';

export default function BusinessContact({
    businessContact,
    updateContact,
}: {
    businessContact: IBusinessContact;
    updateContact: (c: IBusinessContact, remove?: boolean) => void;
}) {
    const [editing, setEditing] = useState(false);
    const small = useMediaQuery(theme.breakpoints.down('sm'));

    function editBusinessContact() {
        setEditing(true);
    }

    function removeContact() {
        client(`api/business-contact/${businessContact.id}/`, {
            method: 'PATCH',
            body: { business_location: null },
        })
            .then(() => updateContact(businessContact, true))
            .catch(consoleLogInDev);
    }
    return (
        <Grid
            item
            container
            direction="row"
            justify="space-between"
            style={{
                borderRadius: 18,
                padding: 20,
                margin: 10,
                backgroundColor: Colors.dullTangerine,
            }}
            xs={12}
            md={4}
        >
            <Grid item container direction="row" justify="space-between">
                <Text bold textStyle={{ maxWidth: '80%', overflow: 'auto' }}>
                    {businessContact.user.first_name} {businessContact.user.last_name}
                </Text>
                <IconButton
                    style={{ position: 'relative', bottom: 15, left: 15 }}
                    icon={ClearIcon}
                    color={Colors.darkNavy}
                    onClick={removeContact}
                />
            </Grid>
            {businessContact.user.phone_number ? (
                <Text bold variant="caption">
                    Phone Number:{' '}
                    <Text inline variant="caption">
                        {formatPhoneNumber(businessContact.user.phone_number)}{' '}
                    </Text>
                </Text>
            ) : null}
            {businessContact.user.email ? (
                <Text bold variant="caption">
                    Email:{' '}
                    <Text variant="caption" inline>
                        {businessContact.user.email}{' '}
                    </Text>
                </Text>
            ) : null}
            <Text bold variant="caption">
                Preferred Contact Method:{' '}
                <Text variant="caption" inline textStyle={{ textTransform: 'capitalize' }}>
                    {businessContact.user.communication_preference.toLowerCase()}
                </Text>
            </Text>
            <Grid xs={3} item container direction="column">
                <Text
                    onClick={() => editBusinessContact()}
                    variant="caption"
                    textStyle={{
                        textAlign: 'right',
                        cursor: 'pointer',
                        textDecorationLine: 'underline',
                    }}
                >
                    Edit
                </Text>
            </Grid>
            <SizeableRoundedDialog
                open={editing}
                onClose={() => setEditing(false)}
                closeButton
                style={{ maxWidth: small ? '100%' : 300 }}
            >
                <EditExistingContact
                    businessContact={businessContact}
                    setEditing={setEditing}
                    updateContact={updateContact}
                />
            </SizeableRoundedDialog>
        </Grid>
    );
}
