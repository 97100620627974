import React from 'react';
import { Checkbox, PrimaryButton, SecondaryButton, Text } from 'library';
import TextInput from 'parent-portal/BusinessLocation/TextInput';
import { Grid } from '@material-ui/core';
import { DocumentTypeWithCount, DropDownField, PendingDocumentFilters } from '../models';
import DocumentTypeSelection from './DocumentTypeSelection';

const allDocTypes: DropDownField = {
    name: 'All Types',
    value: '-1',
};

export default function HeaderActions({
    search,
    filters,
    setFilters,
    getData,
    documentTypes,
}: {
    search: () => void;
    filters: PendingDocumentFilters;
    setFilters: (filters: PendingDocumentFilters) => void;
    getData: (filters: PendingDocumentFilters) => void;
    documentTypes: DocumentTypeWithCount[];
}) {
    const formattedDocumentTypes = [
        allDocTypes,
        ...documentTypes.map((docType) => ({
            name: docType.name + ` (${docType.unverified_count})`,
            value: docType.id.toString(),
        })),
    ];

    function updateUserIdFilter(e: string) {
        const newVal = parseInt(e);
        const newUserId = Number.isInteger(newVal) ? newVal : undefined;
        setFilters({ ...filters, user_id: newUserId, page: 1 });
    }

    function updateDocTypeFilter(e: number) {
        setFilters({ ...filters, document_type_id: e });
    }
    const additionalFilters = ['ready_to_work', 'worked_before'];

    const selectedUserId = filters.user_id?.toString() ?? '';
    const selectedDocType = filters.document_type_id?.toString() ?? '-1';

    return (
        <>
            <Text variant="display">Verify Documents</Text>
            <Grid
                item
                container
                direction="row"
                style={{ gap: 20, marginTop: 20 }}
                xs={12}
                alignItems="center"
                wrap="nowrap"
            >
                <Grid item container xs="auto" style={{ width: 500 }}>
                    <Grid item container direction="row" style={{ width: 500, gap: 10 }}>
                        <Grid item container alignItems="baseline" style={{ width: 200 }}>
                            <TextInput value={selectedUserId} label="User ID" onChange={updateUserIdFilter} />
                        </Grid>
                        <Grid item container direction="column" justify="center" style={{ width: 200 }}>
                            <Text bold textStyle={{ width: 200 }}>
                                Document Type:{' '}
                            </Text>
                            <DocumentTypeSelection
                                documentTypes={formattedDocumentTypes}
                                selectedType={selectedDocType}
                                updateSelected={updateDocTypeFilter}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container style={{ gap: 20 }}>
                        {additionalFilters.map((filter) => (
                            <Checkbox
                                style={{ width: 'unset' }}
                                key={filter}
                                label={filter.split('_').join(' ')}
                                checked={filters[filter as keyof PendingDocumentFilters] as boolean}
                                onChange={(checked) => setFilters({ ...filters, [filter]: checked })}
                            />
                        ))}
                    </Grid>
                </Grid>
                <PrimaryButton buttonStyle={{ width: 200, maxHeight: 50 }} onClick={search}>
                    Search
                </PrimaryButton>
                <SecondaryButton
                    buttonStyle={{ width: 200, maxHeight: 50 }}
                    onClick={() => {
                        setFilters({});
                        getData({});
                    }}
                >
                    Clear
                </SecondaryButton>
            </Grid>
        </>
    );
}
