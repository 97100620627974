import { client, consoleLogInDev } from 'shared';

export async function getMessageCount() {
    try {
        const result = await client('count-unread-threads/?all=True');
        return result.count;
    } catch (error) {
        consoleLogInDev(error);
        return 0;
    }
}
