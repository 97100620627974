import { isMobile } from 'react-device-detect';
import { makeStyles } from '@material-ui/core';
import theme from '../theme';

export const useStyles = makeStyles({
    paper: {
        borderRadius: !isMobile ? 32 : undefined,
        padding: 30,
        width: '100%',
    },
    mainContent: () => ({
        height: 200,
        width: 215,
        borderRadius: 25,
    }),
    grid_img: () => ({
        height: 115,
        marginBottom: 5,
    }),
    minorText: { fontWeight: 'bold', color: theme.palette.primary.main },
    linkText: () => ({
        color: theme.palette.primary.main,
        '&:hover': {
            cursor: 'pointer',
            textDecoration: 'underline',
        },
    }),
    card: () => ({
        padding: 10,
        height: 200,
        width: 215,
        borderRadius: 25,
        cursor: 'pointer',
        // boxShadow: '2px 2px ' + theme.typography.h1.color,
    }),
});
