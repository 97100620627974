import React, { useEffect, useState } from 'react';
import { Grid, useMediaQuery } from '@material-ui/core';
import theme from 'theme';
import { BusinessJobWithAppointments, RegionAdminUser } from 'models';
import { OutlinedTextInput, SizeableRoundedDialog, Checkbox, Text } from 'library';
import TadaIcon from 'assets/icons/tandempay/tada-circle.svg';
import PageHeader from 'parent-portal/payments/Components/ConnectPaymentModal/PageHeader';
import { client, consoleLogInDev } from 'shared';
import LoadingButton from 'reusableComponents/loadingButton';
import { useUserContext } from 'UserContext';
import { getAccountManagementAdmins } from './substituteApi';

export default function EmailCenterShiftsModal({
    closeModal,
    modalOpen,
    job,
}: {
    closeModal: () => void;
    modalOpen: boolean;
    job: BusinessJobWithAppointments;
}) {
    const { user } = useUserContext();
    const [days, setDays] = useState<number | undefined>(undefined);
    const [loading, setLoading] = useState(false);
    const [addressOnly, setAddressOnly] = useState(true);
    const [jobOnly, setJobOnly] = useState(false);
    const [includeAttachments, setIncludeAttachments] = useState(true);
    const [showUnfilledShifts, setShowUnfilledShifts] = useState(false);
    const [testEmail, setTestEmail] = useState(false);
    const [body, setBody] = useState('');
    const [emails, setEmails] = useState('');
    const [subject, setSubject] = useState('');
    const [admins, setAdmins] = useState<RegionAdminUser[]>([]);
    const [fromEmail, setFromEmail] = useState<string>();
    const small = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        if (!modalOpen) return;
        async function getAdmins() {
            const allAdmins = await getAccountManagementAdmins();
            if (user && allAdmins.some((admin: RegionAdminUser) => admin.email === admin.email)) {
                setFromEmail(user.email);
            }
            setAdmins(allAdmins);
        }
        getAdmins();
    }, [modalOpen]);

    function emailCenter() {
        const business_id = job.business.length ? job.business[0].id : null;
        const address_id = job.address.id;

        const requestBody = {
            body,
            business_id,
            days,
            emails: emails ? emails.split(',').map((s) => s.trim()) : [],
            address_id: addressOnly ? address_id : null,
            from_email: fromEmail,
            test_email: testEmail,
            include_unfilled_shifts: showUnfilledShifts,
            include_attachments: includeAttachments,
            subject,
            ongoing_request_id: jobOnly ? job.id : null,
        };

        setLoading(true);
        client(`api/admin-appointment/send-schedule-email/`, { body: requestBody })
            .then(clearAndClose)
            .finally(() => setLoading(false))
            .catch(consoleLogInDev);
    }

    function clearAndClose() {
        setDays(undefined);
        setAddressOnly(true);
        setShowUnfilledShifts(false);
        setJobOnly(false);
        setTestEmail(false);
        setBody('');
        setEmails('');
        setSubject('');
        closeModal();
    }

    return (
        <SizeableRoundedDialog
            closeButton
            open={modalOpen}
            onClose={clearAndClose}
            style={{ height: small ? 560 : 715, width: small ? '100%' : '80%', maxWidth: 700, paddingBottom: 10 }}
        >
            <PageHeader iconSource={<TadaIcon />} headerText="Email center upcoming shifts" subtitleText={''} />
            <Grid item container style={{ padding: 20, gap: 20 }} justify="center" direction="column">
                <Grid style={{ padding: 20 }} item container justify="space-between" alignContent="center">
                    <Checkbox
                        style={{ width: small ? '100%' : '40%' }}
                        label={'Only notify this location'}
                        checked={addressOnly}
                        onChange={() => setAddressOnly(!addressOnly)}
                    />
                    <Checkbox
                        style={{ width: small ? '100%' : '40%' }}
                        label={'Only notify this job'}
                        checked={jobOnly}
                        onChange={() => setJobOnly(!jobOnly)}
                    />

                    <Checkbox
                        style={{ width: small ? '100%' : '40%' }}
                        label={'Include Unfilled Shifts'}
                        checked={showUnfilledShifts}
                        onChange={() => setShowUnfilledShifts(!showUnfilledShifts)}
                    />

                    <Checkbox
                        style={{ width: small ? '100%' : '40%' }}
                        label={'Test Email'}
                        checked={testEmail}
                        onChange={() => setTestEmail(!testEmail)}
                    />

                    <Checkbox
                        style={{ width: small ? '100%' : '40%' }}
                        label={'Include Attachments'}
                        checked={includeAttachments}
                        onChange={() => setIncludeAttachments(!includeAttachments)}
                    />
                    <OutlinedTextInput
                        style={{ width: small ? '100%' : '40%' }}
                        placeholder="Days to include (default is 7)"
                        onChange={(e) => setDays(e.target.value ? parseInt(e.target.value) : undefined)}
                        value={days}
                    />
                </Grid>
                <Grid container item direction="column">
                    <Text>Who is Sending the email?</Text>
                    {admins.map((contact) => (
                        <Checkbox
                            key={contact.id}
                            style={{ width: small ? '100%' : '40%', textTransform: 'capitalize' }}
                            label={contact.first_name}
                            checked={fromEmail === contact.email}
                            onChange={() => setFromEmail(fromEmail === contact.email ? '' : contact.email)}
                        />
                    ))}
                </Grid>
                <OutlinedTextInput
                    multiline
                    placeholder="Comma separated list of additional emails"
                    onChange={(e) => setEmails(e.target.value)}
                    value={emails}
                />
                <OutlinedTextInput
                    multiline
                    placeholder="Additional Body"
                    onChange={(e) => setBody(e.target.value)}
                    value={body}
                />
                <OutlinedTextInput
                    multiline
                    placeholder="Subject (optional)"
                    onChange={(e) => setSubject(e.target.value)}
                    value={subject}
                />
                <LoadingButton
                    buttonStyle={{ maxWidth: '50%', alignSelf: 'flex-end' }}
                    onClick={emailCenter}
                    loading={loading}
                >
                    Submit
                </LoadingButton>
            </Grid>
        </SizeableRoundedDialog>
    );
}
