import React from 'react';
import moment from 'moment';
import { OutlineButton } from 'library';
import { consoleLogInDev } from 'shared';
import { suspendUser, unSuspendUser, sendSuspensionNotification } from './OutreachApi';
import { AdminProviderListUser } from 'models';

export default function OutreachSuspendedAlert({
    user,
    getProvider,
}: {
    user: AdminProviderListUser;
    getProvider: () => void;
}) {
    const handleClick = () => {
        return user.suspended_at ? handleUnsuspend() : handleSuspend();
    };

    const handleUnsuspend = () => {
        unSuspendUser(user.id).then(getProvider).catch(consoleLogInDev);
    };

    const handleSuspend = () => {
        const now = moment().toDate();

        suspendUser(user.id, now)
            .then(() => {
                getProvider();
                sendSuspensionNotification(user.id);
            })
            .catch(consoleLogInDev);
    };

    return (
        <OutlineButton buttonStyle={{ marginRight: 20 }} onClick={() => handleClick()}>
            {user.suspended_at ? `Un-Suspend` : `Suspend User`}
        </OutlineButton>
    );
}
