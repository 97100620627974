import React, { useEffect } from 'react';
import { styled } from '@mui/material';
import PreferredWorkersInput from './PreferredWorkersInput';
import PastWorkersInput from './PastWorkersInput';
import { useJobFormContext } from 'parent-portal/forms/JobFormContext';

export default function RequestedWorkersOnJob({ multiple = true }: { multiple?: boolean }) {
    const { form } = useJobFormContext();

    const { preferredWorkers, pastWorkers, address } = form.fieldStates;

    function clearPastWorkers() {
        pastWorkers.setValue([]);
    }

    function clearPreferredWorkers() {
        preferredWorkers.setValue([]);
    }

    useEffect(() => {
        if (!multiple) {
            if (preferredWorkers.value.length + pastWorkers.value.length === 1) {
                preferredWorkers.setValid(true);
                pastWorkers.setValid(true);
            } else {
                preferredWorkers.setValid(false);
                pastWorkers.setValid(false);
            }
        }
    }, [pastWorkers.value, preferredWorkers.value]);

    return (
        <>
            <PreferredWorkersInput
                selected={preferredWorkers.value}
                onChange={preferredWorkers.setValue}
                addresses={address.value?.map((x) => x.id)}
                multiple={multiple}
                clearOtherInput={clearPastWorkers}
            />
            <Spacer />
            <PastWorkersInput
                selected={pastWorkers.value}
                onChange={pastWorkers.setValue}
                multiple={multiple}
                clearOtherInput={clearPreferredWorkers}
            />
            <Spacer />
        </>
    );
}

const Spacer = styled('div')({
    marginTop: 30,
});
