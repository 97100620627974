import React from 'react';
import { Typography, Grid, Avatar } from '@material-ui/core';
import theme from '../../../../../theme';
import { Provider } from 'models';
import { useStyles } from '../../../../../styles/ProviderJobRequestStyle';
import { PrimaryButton } from 'library';
const PROFILE = require('../../../../../assets/images/default-sitter.png');

interface IProps {
    onClose: () => void;
    providers: Provider[];
    sentButtonText?: string;
}

export default function MessageSent({ onClose, providers, sentButtonText }: IProps) {
    const Styles = useStyles();
    const provider = providers.length === 1 ? providers[0] : undefined;

    return (
        <Grid container justify="center">
            <Grid container xs={12} sm={10} md={6}>
                <Grid item container justify="center">
                    <Typography variant="h5" style={{ color: theme.palette.primary.main }}>
                        Your invitation was sent to {provider?.user.first_name ?? `${providers.length} providers`}!
                    </Typography>
                </Grid>
                <Grid item container justify="center" style={{ paddingTop: 20, paddingBottom: 20 }}>
                    {provider ? (
                        <img
                            alt="profile avatar"
                            className={Styles.mainContent}
                            src={provider?.profile_image?.image ?? PROFILE}
                        />
                    ) : (
                        providers.map((p) => (
                            <Avatar
                                alt={p?.user.first_name}
                                src={p?.profile_image?.image ?? PROFILE}
                                style={{
                                    marginLeft: -20,
                                    border: 'solid 2px #fff',
                                    height: 100,
                                    width: 100,
                                }}
                            />
                        ))
                    )}
                </Grid>
                <Grid container item justify="center" style={{ paddingTop: 10 }}>
                    <PrimaryButton onClick={onClose} buttonStyle={{ maxWidth: 150 }}>
                        {sentButtonText}
                    </PrimaryButton>
                </Grid>
            </Grid>
        </Grid>
    );
}
