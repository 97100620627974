import React, { useState } from 'react';
import { TaskListItem } from '../matchingInboxModels';
import { Chip, Colors, Link, Text, TextButton, IconButton } from 'library';
import { Divider, Grid, styled } from '@mui/material';
import { formatMonthDayYearWith12HourTime } from 'shared/Dates';
import ResolveButton from './ResolveButton';
import { isAfter } from 'date-fns';
import TaskMenu from './TaskMenu';
import BusinessLocationModal from 'internal/business-location/BusinessLocationModal';
import ProviderDetailsOutreachModal from 'internal/texting/outreach/ProviderDetailsOutreachModal';
import { OpenInNew } from '@mui/icons-material';

const TANDEM_BOT_USER_ID = 240;

export default function Task({
    task,
    refresh,
    noDivider,
    onEdit,
    onResolve,
}: {
    task: TaskListItem;
    refresh: () => void;
    noDivider?: boolean;
    onEdit: (item: TaskListItem) => void;
    onResolve: () => void;
}) {
    const [providerModalOpen, setProviderModalOpen] = useState(false);
    const dueDate = task.expires_at ? formatMonthDayYearWith12HourTime(task.expires_at) : null;
    const overdue = !!task.expires_at && isAfter(new Date(), new Date(task.expires_at));
    const [isBusinessLocationModalOpen, setIsBusinessLocationModalOpen] = React.useState(false);
    const taskTypes = {
        Call: Colors.mediumTerraCotta,
        Email: Colors.turquoise,
        Text: Colors.darkNavy,
        Reminder: Colors.mediumRed,
        Alert: Colors.terraCotta,
    };

    const list = task.body.split('--').filter((item) => item.trim() !== '');
    const sanitizedList = list.map((item, index) => {
        const firstSplit = item.split('<<');

        if (firstSplit.length === 1) {
            return (
                <Text key={index} variant="body2">
                    {firstSplit[0]}
                </Text>
            );
        }

        const secondSplit = firstSplit[1].split('>>');

        const firstParen = secondSplit[0]?.lastIndexOf('(');
        const lastParen = secondSplit[0]?.lastIndexOf(')');
        const businessId =
            firstParen !== -1 && lastParen !== -1 ? secondSplit[0].substring(firstParen + 1, lastParen) : null;

        return (
            <Text key={index} variant="body2">
                {firstSplit[0]}
                <Link onClick={() => window.open(`/adminperson/business-location/${businessId}`)}>
                    {secondSplit[0]}
                </Link>
                {secondSplit[1]}
            </Text>
        );
    });

    return (
        <>
            <StyleContainer
                container
                direction="row"
                justifyContent="space-between"
                flexWrap="nowrap"
                backgroundColor={task.created_by?.id === TANDEM_BOT_USER_ID ? Colors.lightGreen : ''}
            >
                <Grid container direction="row" flexWrap="nowrap">
                    <StyledPost />
                    <Grid item style={{ paddingLeft: 8 }}>
                        <Text bold>
                            {task.subject}
                            {task.business_location && (
                                <>
                                    <TextButton
                                        onClick={() => setIsBusinessLocationModalOpen(true)}
                                        buttonStyle={{ width: undefined, marginLeft: 8, marginBottom: 4 }}
                                    >
                                        {task.business_location.name}
                                    </TextButton>
                                    <IconButton
                                        icon={OpenInNew}
                                        backgroundColor="transparent"
                                        style={{ boxShadow: 'none' }}
                                        onClick={() =>
                                            window.open(`/adminperson/business-location/${task.business_location?.id}`)
                                        }
                                    />
                                </>
                            )}
                            {task.user && (
                                <>
                                    <TextButton
                                        onClick={() => setProviderModalOpen(true)}
                                        buttonStyle={{ width: undefined, marginLeft: 8, marginBottom: 4 }}
                                    >
                                        {task.user.first_name} {task.user.last_name}
                                    </TextButton>
                                    <ProviderDetailsOutreachModal
                                        providerUserId={task.user.id}
                                        open={providerModalOpen}
                                        onClose={() => setProviderModalOpen(false)}
                                    />
                                </>
                            )}
                            {task.item_type_display && (
                                <Chip
                                    style={chipStyle}
                                    backgroundColor={taskTypes[task.item_type_display]}
                                    textColor={Colors.white}
                                    label={task.item_type_display}
                                />
                            )}
                        </Text>
                        {sanitizedList?.length > 1 ? (
                            <ul>
                                {sanitizedList.map((item, index) => (
                                    <li key={index} style={{ paddingBottom: 3 }}>
                                        <Text variant="body2">{item}</Text>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <Text variant="body2">{sanitizedList[0]}</Text>
                        )}
                        {dueDate ? (
                            <>
                                <Text variant="body2">
                                    <Text variant="body2" bold inline>
                                        Due at:{' '}
                                    </Text>
                                    {dueDate}
                                    {overdue ? (
                                        <Text variant="caption" bold inline color="red">
                                            {' '}
                                            (Overdue)
                                        </Text>
                                    ) : null}
                                </Text>
                            </>
                        ) : null}
                    </Grid>
                </Grid>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <ResolveButton task={task} onResolve={onResolve} />
                    <TaskMenu task={task} refresh={refresh} onEdit={onEdit} />
                </div>
            </StyleContainer>
            {task.business_location && (
                <BusinessLocationModal
                    businessLocationId={task.business_location?.id}
                    open={isBusinessLocationModalOpen}
                    onClose={() => setIsBusinessLocationModalOpen(false)}
                />
            )}
            {noDivider ? null : <StyledDivider />}
        </>
    );
}

const StyleContainer = styled(Grid)(({ backgroundColor }: { backgroundColor: string }) => ({
    backgroundColor: backgroundColor ? backgroundColor : 'initial',
}));

const StyledPost = styled('div')({
    backgroundColor: Colors.darkNavy,
    minWidth: 8,
    width: 8,
});

const StyledDivider = styled(Divider)({
    borderColor: Colors.mediumGrey,
    borderTopWidth: 0.5,
    borderBottomWidth: 0,
    marginTop: 8,
    marginBottom: 8,
});

const chipStyle = {
    marginLeft: 4,
    minHeight: 24,
    height: 24,
};
