import { Address, INestedUserReference, LeanUser, LeanUserWithPhone, Note } from 'models';
import { NestedLabel, ObjectLabel } from 'models/Labels';
import { IAdminMedicalRecord, IMedicalAppointment } from './MedicalRecord';
import { IEducationRecord } from './EducationRecord';
import { ApiTimeslot } from 'internal/substitutes/substituteModels';

interface Option {
    value: string;
}

interface Qualification {
    name: string;
}

interface Qualifications {
    id: number;
    option: Option | undefined | null;
    qualification: Qualification | undefined | null;
    value: string | null;
    calculated_at: string | null;
}

export interface OCCRRAEducations {
    institution_name: string | null;
    attended_from: string | null;
    attended_to: string | null;
    major_name: string | null;
    verification_status: string | null;
}

export interface OCCRRATrainings {
    verification_status: string | null;
    title: string | null;
    hours: string | null;
}

export interface OCCRRAInfoCollect {
    id: number;
    cpl_level: number;
    occrra_trainings: OCCRRATrainings[] | null;
    occrra_educations: OCCRRAEducations[] | null;
    background_check_link: string;
    admin_status: string | null;
}

export interface IDocument {
    document: string;
    title: string;
    id: number;
    added_at: string;
    verified_at: string;
    ignored_at: string;
    download_path: string;
    document_type_name: string;
}

export interface AdminProviderPastExperience {
    id: number;
    title: string;
    employer: string;
    start_date: string;
    end_date: string;
}

export interface AdminProvider extends AdminListProvider {
    success_progress: BabysitterSuccessProgress[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    childcare_center_qualification: any[];
    business_hires: number;
    qualifications: Qualifications[];
    medical_record?: IAdminMedicalRecord[];
    medical_appointment?: IMedicalAppointment;
    hs_education_record?: IEducationRecord[];
    documents: IDocument[];
    past_experience: AdminProviderPastExperience[];
    med_flow_status: string;
    bci_fbi_info_collect: IBciFbiInfoCollect[];
    licensed_center_employment_history?: Employments;
    rebook_businesses: number[];
    extra_attributes: { input: string; value: string }[];
    businesses_outlawed: number[];
    business_locations_outlawed: number[];
    occrra_info_collect?: OCCRRAInfoCollect;
    signed_form_718b?: string;
    ready_to_work?: boolean;
    tandem_start_date: string | null;
    employment_and_training_summary: {
        staff_credential_expiration?: string;
        director_credential_expiration?: string;
        school_readiness?: {
            school_readiness_complete: boolean;
            verified_hnsr: boolean;
            verified_safesr: boolean;
            verified_devsr: boolean;
        };
        compliance_warnings?: IComplianceWarnings[];
        has_verified_staff_credential?: boolean;
        has_verified_director_credential?: boolean;
        has_verified_vpk_credential?: boolean;
    };
    compliance_state: string;
    sesame_appointment?: ISesameAppointment | null;
    compliance_hold?: boolean;
    all_qualifications: string;
    first_vetted_date?: string;
}

export interface ISesameAppointment {
    id: string;
    booked_at: string;
    transaction_id: string;
    booked_appointment_datetime: string;
    doctor_name: string;
    video_link: string;
}
export interface IComplianceWarnings {
    title: string;
    paragraphs: ComplianceWarningParagraph[];
}
export interface ComplianceWarningAction {
    button_text: string;
    url: string;
    type: string;
    options?: ComplianceActionOption[];
    modal_title?: string;
    modal_message?: string;
}

export interface IHandleActionParams {
    data?: { [key: string]: string };
    method: string;
}

export interface ComplianceActionOption {
    display: string;
    data: IHandleActionParams['data'];
    method: string;
}

export interface ComplianceWarningParagraph {
    type: 'error' | 'description';
    title: string;
    message: string;
    action?: ComplianceWarningAction;
}

interface Employments {
    ignored_at: string | null;
    id: number;
    center_name: string;
    end_date: string;
    center_location: string;
    listed_has_not_worked_in_center: boolean;
    listed_completed_background_check: boolean;
    listed_was_k_12_program: boolean;
}
export interface IBciFbiInfoCollect {
    appointment_id: number;
    appointment_location_city: string;
    appointment_location_name: string;
    appointment_location_state: string;
    appointment_location_street_address: string;
    appointment_location_zip: string;
    appointment_time: string;
    id: number;
}
export interface ThreadProvider {
    id: number;
    labels?: ObjectLabel[];
}

export interface BabysitterSuccessProgress {
    bci_fbi_appointment_date: string;
    billed_for_fingerprinting: string;
    completed_background_check_on_occrra: string;
    completed_profile: string;
    fingerprint_location: string;
    provider_completed_bci_fbi_background_check: string;
    scheduled_bci_fbi_over_the_phone: string;
    scheduled_with_tandie_to_get_bci_fbi: string;
    sent_calendar_invite_for_fbi_bci_appointment: string;
    indicated_not_interested: string;
    id: number;
    ready_for_jobs: string;
    verified_medical_records_date: string;
    education_verification_record_verified: string;
    provider_completed_background_check_florida: string;
    verified_background_check_florida: string;
    verified_work_history?: string;
}

export interface LeanAdminProvider {
    user: AdminProviderListUser | AdminProviderListUser | LeanUserWithPhone | LeanUser;
}

export interface INestedBabysitterReference {
    id: number;
    user: INestedUserReference;
}

export interface IUserStats {
    review_average: number;
    review_count: number;
    cancellations_count: number;
    hour_reports_count: number;
    on_time_hour_reports_count: number;
    shifts_worked: number;
    streak_length: number;
}

export interface AdminListProvider {
    user: AdminProviderListUser;
    id: number;
    education: string;
    bio: string;
    birthday: string;
    pinned_notes: Note[];
    attributes: { id: number; name: string }[];
    labels: NestedLabel[];
    sub_availability: ApiTimeslot[] | null;
    cancellation_reset: string;
    outstanding_matches?: number;
    user_on_probation?: boolean;
    user_stats?: IUserStats;
    user_qualifications?: string;
    assumed_availability?: ApiTimeslot[];
    last_login: string;
    extra_attributes: { input: string; value: string }[];
    region_info?: { area_id: number; area: string };
}

export interface AdminProviderListUser {
    first_name: string;
    last_name: string;
    phone_number: string;
    id: number;
    address: Address;
    last_login: string;
    suspended_at: Date;
    email: string;
    compliance_state: string;
    show_spotlight: boolean;
}

export interface IInfoModalDoc {
    type: string;
    text?: string;
    url: string;
    id: number;
    added_at?: string;
    verified_at?: string;
    ignored_at?: string;
    name?: string;
}

export const FOLLOWUP_FLAG = 'ADMIN_MAP_FOLLOWUP_FLAG';
