import React, { useState } from 'react';
import { Text } from 'library';
import { useJobFormContext } from 'parent-portal/forms/JobFormContext';
import { styled } from '@mui/material';
import SectionContainer from './SectionContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Colors from 'constants/Colors';
import { faSquareCheck } from '@fortawesome/free-solid-svg-icons';
import EditRequestedWorkersModal from './EditRequestedWorkersModal';

export default function RequestedWorkersSection() {
    const [editOpen, setEditOpen] = useState(false);
    const { form } = useJobFormContext();
    const { pastWorkers, preferredWorkers } = form.fieldStates;
    const allRequests = [...preferredWorkers.value, ...pastWorkers.value];
    return (
        <SectionContainer
            editModal={<EditRequestedWorkersModal open={editOpen} onClose={() => setEditOpen(false)} />}
            setEditOpen={setEditOpen}
        >
            <Text variant="h2" bold textStyle={styles.header}>
                Requested Workers
            </Text>
            {allRequests.length > 0 ? (
                allRequests.map((worker) => (
                    <WorkerContainer key={worker.id}>
                        <FontAwesomeIcon icon={faSquareCheck} color={Colors.turquoise} />
                        <Text variant="body2">{worker.name}</Text>
                    </WorkerContainer>
                ))
            ) : (
                <Text variant="body2">Click edit to request past workers for this job.</Text>
            )}
        </SectionContainer>
    );
}

const styles = {
    header: {
        marginBottom: 8,
    },
};

const WorkerContainer = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
    alignItems: 'center',
});
