import React from 'react';
import MedicalRecordVerify from './VerificationPages/MedicalRecordVerify';
import GeneralDocumentVerify from './VerificationPages/GeneralDocumentVerify';
import { DocumentVerificationContentProps } from './models';

export default function DocumentVerificationContent({
    medicalRecord,
    signedAt,
    setSignedAt,
    expirationDate,
    setExpirationDate,
    isSesameDocument,
    setIsSesameDocument,
    documentRequirements,
    requirementOptions,
    updateRequirementOptions,
}: DocumentVerificationContentProps) {
    const contentOptions = {
        medDoc: (
            <MedicalRecordVerify
                setSignedAt={setSignedAt}
                signedAt={signedAt}
                isSesameDocument={isSesameDocument}
                setIsSesameDocument={setIsSesameDocument}
            />
        ),
        generalDoc: (
            <GeneralDocumentVerify
                documentRequirements={documentRequirements}
                setExpirationDate={setExpirationDate}
                expirationDate={expirationDate}
                updateRequirementOptions={updateRequirementOptions}
                requirementOptions={requirementOptions}
            />
        ),
    };
    const specializedDoc = medicalRecord ? 'medDoc' : 'generalDoc';

    return contentOptions[specializedDoc as keyof typeof contentOptions];
}
