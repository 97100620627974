import { PagingResult } from 'models';
import { client, queryStringifyObject } from 'shared';
import moment from 'moment';
import type { DayOfWeekSelections, ProviderSelection } from 'parent-portal/forms/JobRequestTypes';

export async function getPastWorkers(jobTimes?: JobTimes): Promise<PastWorkerDTO[]> {
    const sitterChoices = await client('api/past-workers/', { method: 'POST', body: { job_times: jobTimes } });
    return sitterChoices.past_sitters;
}

export interface PastWorkerDTO {
    first_name: string;
    last_name: string;
    babysitter_id: number;
    id: number;
    status?: string;
}

export interface JobTimes {
    start: moment.Moment | null;
    end: moment.Moment | null;
    days_of_week: DayOfWeekSelections;
}

export function getPreferredWorkers(
    page = 1,
    addresses?: number[],
    jobTimes?: JobTimes,
): Promise<PagingResult<PreferredWorkerDTO>> {
    const addressString = addresses && addresses.length > 0 ? addresses.join(',') : undefined;
    const queryString = queryStringifyObject({});
    return client(`api/preferred-worker/workers-with-status/?${queryString}`, {
        method: 'POST',
        body: {
            page,
            page_size: 5,
            addresses: addressString,
            distinct_workers: true,
            job_times: jobTimes,
        },
    });
}

export interface PreferredWorkerDTO {
    id: number;
    user: {
        first_name: string;
        last_name: string;
        id: number;
    };
    business_location: {
        id: number;
        name: string;
    };
    business: {
        id: number;
        name: string;
    };
    status?: string;
}

export interface PreferredJobSitterDTO {
    preferred_sitters: {
        sitter: number;
    }[];
}

export function convertPreferredSitterObject(
    preferredProviders: ProviderSelection[],
    pastProviders: ProviderSelection[],
): PreferredJobSitterDTO {
    const nonDupedPastProviders = pastProviders.filter(
        (past) => !preferredProviders.some((preferred) => preferred.id === past.id),
    );
    const allPreferred = [...preferredProviders, ...nonDupedPastProviders];
    return {
        preferred_sitters: allPreferred.map((preferredProvider: ProviderSelection) => ({
            sitter: preferredProvider.id,
        })),
    };
}
