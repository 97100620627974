import { AdminProvider, IDocument } from 'models';
import { DCF_TRANSCRIPT } from 'api/AdminDocumentApi';

const EXCLUDED_DOC_NAMES = [
    'OCCRRA Training Summary',
    'DCF Training Transcript',
    'Resume',
    'Background Check Document',
    'Professional Development Report Document',
];

export function getLinks(provider: AdminProvider) {
    const occrraInfo = provider?.occrra_info_collect;

    function filterDocs(document: IDocument) {
        return (
            !!document.verified_at ||
            !!document.ignored_at ||
            EXCLUDED_DOC_NAMES.some((name) => document.document_type_name.includes(name))
        );
    }

    const documents = provider?.documents.filter(filterDocs).map((x) => {
        if (x.document_type_name === 'Character Reference Document') {
            const reference_doc_number = parseInt(x.title?.split('_')[2]);
            x.document_type_name = Number.isInteger(reference_doc_number)
                ? `${x.document_type_name} ${reference_doc_number}`
                : x.document_type_name;
        }
        const dcfDoc = x.document_type_name.includes(DCF_TRANSCRIPT);
        return {
            type: 'download',
            text: x.document_type_name,
            url: x.download_path,
            id: x.id,
            added_at: x.added_at,
            verified_at: dcfDoc ? '' : x.verified_at,
            ignored_at: x.ignored_at,
        };
    });

    if (occrraInfo?.background_check_link) {
        documents.push({
            type: 'link',
            text: 'FBI/BCI Background Check',
            url: occrraInfo.background_check_link,
            id: occrraInfo.id,
            added_at: '',
            verified_at: '',
            ignored_at: '',
        });
    }

    if (provider?.signed_form_718b) {
        documents.push({
            type: 'download',
            text: 'Form 718b',
            url: `api/illinois-info-collect/download-bgc-auth/?provider=${provider.id}`,
            id: parseInt(new Date(provider.signed_form_718b).getTime().toString().slice(-7)), // NOTE - unique id based on the epoch time version of when the form was added
            added_at: provider.signed_form_718b,
            verified_at: provider.signed_form_718b,
            ignored_at: '',
        });
    }
    return documents;
}
