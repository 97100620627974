import React, { useState } from 'react';
import { Text, Colors, PrimaryButton, SecondaryButton } from 'library';
import { Grid, useMediaQuery } from '@mui/material';
import HomeHeaderImage from 'assets/images/marketing-site/home-header-arch.png';
import theme from 'theme';
import LearnMoreModal from 'parent-portal/public/on-demand/LearnMore/LearnMoreModal';

export function HomeHeader({ signUp }: { signUp: () => void }) {
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));
    const [openLearnMore, setOpenLearnMore] = useState(false);
    return (
        <Grid
            container
            xs={12}
            style={{
                backgroundColor: Colors.lightTurq,
                paddingLeft: smDown ? 40 : 80,
                paddingRight: smDown ? 40 : 80,
            }}
        >
            <Grid container item justifyContent="space-between">
                <Grid item container xs={12} md={5} flexDirection="column" style={{ marginTop: '15%' }}>
                    <Text variant="display" textStyle={{ width: 'unset' }}>
                        Staff your child care program with compliant, quality workers.
                    </Text>
                    <Grid item container style={{ gap: 20 }} flexDirection="column">
                        <Text variant="body1" textStyle={{ paddingTop: '1.5%', width: 'unset' }}>
                            Tandem connects you to job-ready temporary and permanent childcare workers, as soon as you
                            need coverage.
                        </Text>
                        <PrimaryButton id="btnCreateBusAccount" buttonStyle={{ width: 200 }} onClick={signUp}>
                            Get Started
                        </PrimaryButton>
                        <SecondaryButton
                            id="btnLeanCreateBusAccount"
                            buttonStyle={{ width: 200 }}
                            onClick={() => setOpenLearnMore(true)}
                        >
                            Learn More
                        </SecondaryButton>
                    </Grid>
                </Grid>
                <img src={HomeHeaderImage} alt="home-header" style={{ maxWidth: smDown ? '90%' : '50%' }} />
            </Grid>
            <LearnMoreModal open={openLearnMore} onClose={() => setOpenLearnMore(false)} />
        </Grid>
    );
}
