import React, { useEffect, useState } from 'react';
import { useModalsContext } from 'ModalsContext';
import { SizeableRoundedDialog as Dialog } from 'library';
import BusinessDateAndTimePage from './components/pages/DateAndTimePage/BusinessDateAndTimePage';
import BusinessJobHeadlinePage from './components/pages/HeadlineDescriptionPage/BusinessJobHeadlinePage';
import BusinessPayPage from './components/pages/PayPage/BusinessPayPage';
import PaySetupPage from './components/pages/PaySetupPage/PaySetupPage';
import { useJobFormContext, JobFormContextProvider } from './JobFormContext';
import { useHistory } from 'react-router';
import { useUserContext } from 'UserContext';
import moment from 'moment';
import BusinessPaymentModelPage from './components/pages/BusinessPaymentModelPage/BusinessPaymentModelPage';
import ConnectPaymentModal from 'parent-portal/payments/Components/ConnectPaymentModal';
import BusinessJobRequirementsPage from './components/pages/BusinessJobRequirementsPage/BusinessJobRequirementsPage';
import BusinessLocationPage from './components/pages/BusinessLocationPage';
import BusinessJobSuccessPage from './components/pages/ConfirmationPage/BusinessJobSuccessPage';
import ProviderPreferencesJobFormPage from './components/pages/ProviderPreferencesPage/ProviderPreferencesJobFormPage';
import MissingMicrodepositVerificationModal from 'parent-portal/home/paymentVerificationWarning/MissingMicrodepositVerificationModal';
import useMicrodepositVerificationInfo from 'parent-portal/home/paymentVerificationWarning/useMicrodepositVerficationInfo';
import { TemplateOption } from './JobRequestTypes';
import SelectTemplatePage from './components/pages/SelectTemplatePage';
import JobSummaryPage from './components/pages/JobSummaryPage';
import TrialRunToSubJobPage from './components/pages/TrialRunToSubJobPage/TrialRunToSubJobPage';
import JobFaqPage from './components/pages/JobFaqPage/JobFaqPage';

function BusinessJobRequestForm() {
    const { form, reset, currentPage, createdJob, setPageCount, setFormErrors, showModalClose, setShowModalClose } =
        useJobFormContext();
    const { user, role } = useUserContext();
    const { blockJobPostingOnVerification } = useMicrodepositVerificationInfo();
    const { businessJobFormOpen, closeBusinessJobForm } = useModalsContext();
    const [showPaymentSetup, setShowPaymentSetup] = useState(false);

    const [showTandemPaySetup, setShowTandemPaySetup] = useState(false);
    const { fieldStates } = form;
    const history = useHistory();
    const { setValid, setValue } = fieldStates.jobTypeId;
    const business = user?.businesses?.length ? user.businesses[0] : null;

    useEffect(() => {
        setValue(1);
        setValid(true);
        form.fieldStates.endDate.setValue(moment());
    }, []);

    useEffect(() => {
        if (role === 'business_active') {
            const paymentSetup = !!user?.payment_information && user?.payment_information?.length > 0;
            setShowPaymentSetup(!paymentSetup && !localStorage.getItem('old')); //TODO: need a better solution to this for admins
        }
    }, [user, role]);

    const completeFollowUps = () => {
        onClose();
        if (history.location.pathname.includes('/jobs')) history.go(0);
        else if (history.location.pathname.includes('/get-started')) history.push('/home');
    };

    const onClose = () => {
        reset();
        closeBusinessJobForm();
        setFormErrors([]);
    };

    const getPageFlow = () => {
        return fieldStates.useTemplate.value === TemplateOption.TEMPLATE
            ? [<SelectTemplatePage key="select-template-page" name="select-template-page" />]
            : [
                  <BusinessDateAndTimePage key="date-time-page" name="date-time-page" />,
                  <BusinessJobHeadlinePage
                      key="headline-description-page"
                      name="headline-description-page"
                      fieldStates={fieldStates}
                  />,
                  business?.business_type !== 'CHILD_CARE' ? (
                      <BusinessJobRequirementsPage
                          fieldStates={fieldStates}
                          key="qualifications-page"
                          name="qualifications-page"
                      />
                  ) : null,
                  <BusinessLocationPage key="business-location-page" name="business-location-page" />,
                  <ProviderPreferencesJobFormPage
                      key="requested-workers-page"
                      name="requested-workers-page"
                      subtitle="We will prioritize workers that you have had a good experience with in the past."
                  />,
                  <BusinessPayPage key="pay-scales-page" name="pay-scales-page" fieldStates={fieldStates} />,
                  <JobFaqPage key="job-faq-page" name="job-faq-page" />,
                  showPaymentSetup ? (
                      <PaySetupPage
                          setShowTandemPaySetup={setShowTandemPaySetup}
                          key="pay-setup-page"
                          name="pay-setup-page"
                      />
                  ) : null,
              ];
    };

    const pages = [
        <BusinessPaymentModelPage
            key="business-job-type-page"
            name="business-job-type-page"
            fieldStates={fieldStates}
        />,
        ...getPageFlow(),
        <JobSummaryPage key="job-summary-page" name="job-summary-page" />,
        fieldStates.businessJobType.value === 'TRIAL_RUN' || fieldStates.trialRunCoverage.value ? (
            <TrialRunToSubJobPage key="trial-run-to-sub-job-page" name="trial-run-to-sub-job-page" />
        ) : null,
        <BusinessJobSuccessPage
            key="job-success-page"
            job={createdJob}
            onClose={completeFollowUps}
            setShowModalClose={setShowModalClose}
        />,
    ].filter((item) => item !== null);

    useEffect(() => {
        setPageCount(pages.length);
    }, [pages]);
    return (
        <>
            {!showTandemPaySetup && !blockJobPostingOnVerification ? (
                <Dialog
                    open={businessJobFormOpen}
                    onClose={createdJob ? completeFollowUps : onClose}
                    closeButton={showModalClose}
                    style={{ width: '60%', maxWidth: 800, maxHeight: '95vh' }}
                    contentStyle={{ marginTop: 20 }}
                >
                    {pages[currentPage]}
                </Dialog>
            ) : null}
            <ConnectPaymentModal
                isOpen={showTandemPaySetup}
                onClose={() => setShowTandemPaySetup(false)}
                fromRoute={'BusinessJobRequestModal'}
            />
            <MissingMicrodepositVerificationModal
                isOpen={blockJobPostingOnVerification && businessJobFormOpen}
                onClose={() => closeBusinessJobForm()}
            />
        </>
    );
}

export default function BusinessJobFormWithContext() {
    const { role } = useUserContext();
    if (role && role !== 'admin') {
        return (
            <JobFormContextProvider>
                <BusinessJobRequestForm />
            </JobFormContextProvider>
        );
    }
    return null;
}
