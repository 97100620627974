import React from 'react';
import { List, ListItem } from '@material-ui/core';
import { IDashboardData } from '../Models/DashboardData';
import DashboardSection from './DashboardSection';
import { Colors } from 'library';

interface IGuidesProps {
    data?: IDashboardData;
}
export default function Guides({ data }: IGuidesProps) {
    if (!data?.guides.links.length) {
        return <></>;
    }

    return (
        <DashboardSection header="Helpful Links">
            <List>
                {data?.guides.links.map((x) => (
                    <ListItem key={x.id}>
                        <a
                            href={x.document}
                            target="_blank"
                            rel="noreferrer"
                            style={{
                                textDecoration: 'underline',
                                fontSize: 14,
                                color: Colors.darkNavy,
                            }}
                        >
                            {x.name}
                        </a>
                    </ListItem>
                ))}
            </List>
        </DashboardSection>
    );
}
