import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Colors } from 'library';
import { addDays, startOfWeek, eachDayOfInterval, endOfWeek } from 'date-fns';
import { client, consoleLogInDev } from 'shared';
import { formatDateFromDateObject } from 'shared/Dates';
import HiredShift from './HiredShift';
import PostAShiftCard from './PostAShiftCard';
import UnfilledShifts from './UnfilledShifts';
import CalendarHeader from './CalendarHeader';
import CalendarFilters from './CalendarFilters';
import { useMediaQuery } from '@mui/material';
import theme from 'theme';
import useLoading from 'library/hooks/useLoading';
import { BusinessSlot } from 'models';

interface IBusinessCalendarProps {
    addressId?: number;
    businessLocationId?: number;
}

export default function BusinessCalendar({ addressId, businessLocationId }: IBusinessCalendarProps) {
    const mdDown = useMediaQuery(theme.breakpoints.down('md'));
    const [currentDay, setCurrentDay] = useState<Date>(new Date());
    const [showWeekend, setShowWeekend] = useState(false);
    const [hiredShifts, setHiredShifts] = useState<BusinessSlot[]>([]);
    const [unfilledShifts, setUnfilledShifts] = useState<{ [key: string]: BusinessSlot[] }>({});
    const { loading, setLoading, LoadingSpinner } = useLoading();

    useEffect(() => {
        setCurrentDay(mdDown ? new Date() : addDays(startOfWeek(new Date()), 1));
    }, [mdDown]);

    function weekDates(startDate: Date) {
        if (mdDown) return [startDate];
        const interval = {
            start: startDate,
            end: endOfWeek(startDate, { weekStartsOn: 1 }),
        };
        return eachDayOfInterval(interval);
    }

    async function jobsForCurrentInterval() {
        if (addressId) {
            try {
                setLoading(true);
                const interval = weekDates(currentDay);
                const body: { [key: string]: string } = {
                    address_id: addressId.toString(),
                    start: formatDateFromDateObject(interval[0]),
                    include_unfilled: 'true',
                };
                if (interval.length > 1) {
                    body['end'] = formatDateFromDateObject(interval.slice(-1)[0]);
                }
                const params = new URLSearchParams(body).toString();
                const result = await client(`api/business-calendar/calendar/?${params}`);
                setHiredShifts(
                    result.upcoming_shifts.filter(
                        (s: BusinessSlot) =>
                            s.appointments.length > 0 && s.appointments.some((appt) => appt.providers.length > 0),
                    ),
                );
                setUnfilledShifts(
                    unfilledShiftsByDate(
                        result.upcoming_shifts.filter(
                            (s: BusinessSlot) =>
                                s.appointments.length === 0 ||
                                s.appointments.some((appt) => appt.providers.length === 0),
                        ),
                    ),
                );
                setLoading(false);
            } catch (error) {
                consoleLogInDev(error);
            }
        }
    }

    useEffect(() => {
        jobsForCurrentInterval();
    }, [currentDay, addressId]);

    function unfilledShiftsByDate(slots: BusinessSlot[]) {
        return slots.reduce((p: { [key: string]: BusinessSlot[] }, c: BusinessSlot) => {
            const startDate = new Date(c.start_date).toLocaleDateString();
            const current = p[startDate] ?? [];
            return { ...p, [startDate]: [...current, c] };
        }, {});
    }

    function updateCurrentDay(d: Date) {
        setCurrentDay(mdDown ? d : addDays(startOfWeek(d), 1));
    }

    function hiredShiftsForDate(d: Date) {
        return hiredShifts.filter((s) => new Date(s.start_date).toLocaleDateString() === d.toLocaleDateString());
    }

    function CalendarColumns() {
        const dates = showWeekend || mdDown ? weekDates(currentDay) : weekDates(currentDay).slice(0, -2);
        const start = dates[0];
        const end = dates[dates.length - 1];
        return (
            <Grid item container direction="row" justify="center">
                {dates.map((d) => (
                    <Grid
                        key={d.getDay()}
                        container
                        item
                        style={{
                            width: mdDown ? '100%' : `${100 / (showWeekend ? 7 : 5)}%`,
                            borderWidth: 1,
                            borderColor: Colors.disabledGrey,
                            borderStyle: 'solid',
                            borderTopRightRadius: d.getDay() === end.getDay() || mdDown ? 18 : 0,
                            borderBottomRightRadius: d.getDay() === end.getDay() || mdDown ? 18 : 0,
                            borderTopLeftRadius: d.getDay() === start.getDay() || mdDown ? 18 : 0,
                            borderBottomLeftRadius: d.getDay() === start.getDay() || mdDown ? 18 : 0,
                        }}
                    >
                        <CalendarHeader d={d} start={start} end={end} />
                        <Grid
                            style={{
                                overflowX: 'scroll',
                                height: '75vh',
                                paddingBottom: 20,
                                width: '100%',
                            }}
                        >
                            {loading ? (
                                <LoadingSpinner />
                            ) : (
                                <>
                                    {unfilledShifts[d.toLocaleDateString()]?.length > 0 ? (
                                        <Grid style={{ padding: '20px 20px 0px 20px' }}>
                                            <UnfilledShifts
                                                slots={unfilledShifts[d.toLocaleDateString()]}
                                                title={d.toLocaleDateString()}
                                                refresh={jobsForCurrentInterval}
                                            />
                                        </Grid>
                                    ) : null}
                                    {hiredShiftsForDate(d).map((s) => (
                                        <Grid key={s.id} style={{ padding: '20px 20px 0px 20px' }}>
                                            <HiredShift slot={s} refresh={jobsForCurrentInterval} />
                                        </Grid>
                                    ))}
                                    <PostAShiftCard
                                        d={d}
                                        addressId={addressId}
                                        businessLocationId={businessLocationId}
                                    />
                                </>
                            )}
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        );
    }

    return (
        <Grid item container direction="row" xs={12} style={{ borderRadius: 18 }}>
            <CalendarFilters
                currentDay={currentDay}
                setCurrentDay={updateCurrentDay}
                showWeekend={showWeekend}
                setShowWeekend={setShowWeekend}
            />
            <CalendarColumns />
        </Grid>
    );
}
