import moment from 'moment';
import { consoleLogInDev, logRejectionsInDev, client, track } from 'shared';
import { DayOfWeekSelections, PostBodyType, JobOptions, WorkingHoursForDay } from './JobRequestTypes';
import { JobTypeIds } from '../../models/JobType';
import { Job } from 'models';
import { convertPreferredSitterObject } from './components/pages/ProviderPreferencesPage/preferredWorkersApi';

export enum JobType {
    ongoing = 'Ongoing',
    onetime = 'onetime',
}

interface SelectedDay {
    day: string;
    start: moment.Moment;
    end: moment.Moment;
    slots: number;
}

interface JobSubmissionResult {
    providerWasRequested: boolean;
    submissionSucceeded: boolean;
    createdJobId?: number | null;
    job?: Job;
}

export function coerceHours(jobOptions: JobOptions) {
    const workingHoursByDay = getWorkingHours(jobOptions);
    let ongoingTimesOfDay = getOngoingTimesOfDay(workingHoursByDay);
    let selectedWeekDays = workingHoursByDay.map((hoursInfo) => hoursInfo.day);
    if ((!selectedWeekDays || selectedWeekDays.length === 0) && jobOptions.start && jobOptions.end_time) {
        const day = jobOptions.start.toDate().toLocaleString('en-us', { weekday: 'long' }) as keyof DayOfWeekSelections;
        selectedWeekDays = [day];
        ongoingTimesOfDay = [
            {
                [day]: {
                    start: jobOptions.start.format('HH:mm:ss'),
                    end: jobOptions.end_time.format('HH:mm:ss'),
                    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    // eslint-disable-next-line security/detect-object-injection
                    slots: jobOptions.daysOfTheWeek[day].slots,
                },
            },
        ];
    }

    return { selectedWeekDays, ongoingTimesOfDay };
}

export default async function submitJobRequest({
    familyId,
    payMin,
    payMax,
    jobOptions,
    lastName,
    jobType,
    invitedProvider,
    jobTypeId,
    otherJobType,
    acceptedTandemPayAgreement,
    code,
    status,
    jobId,
}: {
    familyId: number;
    payMin: number;
    payMax: number;
    jobOptions: JobOptions;
    lastName: string;
    jobType: JobType;
    invitedProvider: { id: number } | undefined;
    jobTypeId: number;
    otherJobType: string;
    acceptedTandemPayAgreement: boolean | undefined;
    code: string;
    status: string;
    jobId: number;
}): Promise<JobSubmissionResult | PostBodyType> {
    const submissionResult: JobSubmissionResult = {
        submissionSucceeded: true,
        providerWasRequested: false,
    };

    const { selectedWeekDays, ongoingTimesOfDay } = coerceHours(jobOptions);
    let newJobTypeId = undefined;
    if (jobTypeId < 0) {
        const res = await client('api/job-types/', {
            body: { name: otherJobType },
        });

        newJobTypeId = res.id;
    }

    const ongoingRequest = createOngoingRequest(
        selectedWeekDays,
        ongoingTimesOfDay,
        jobOptions,
        payMin,
        payMax,
        familyId,
        acceptedTandemPayAgreement,
        newJobTypeId || jobTypeId,
        jobType,
        status,
    );
    if (status === 'DRAFT') {
        return ongoingRequest;
    }

    const addresses = jobOptions.address;
    for (let i = 0; i < addresses.length; i++) {
        const onGoingToSubmit = {
            ...ongoingRequest,
            address: addresses[i].id,
            business_location: addresses[i].businessLocationId,
        };

        const ongoingResponse = await submitOngoingRequest(onGoingToSubmit, i === 0 ? jobId : 0);

        if (ongoingResponse) {
            if (ongoingResponse.id) {
                const ongoingId = ongoingResponse.id;
                submissionResult.createdJobId = ongoingId;
                submissionResult.job = ongoingResponse;

                if (code) {
                    submitPromoCode(code);
                }

                if (invitedProvider) {
                    await createRequestedPairing(ongoingId, invitedProvider.id, jobType, submissionResult);
                }
            } else {
                submissionResult.submissionSucceeded = false;
            }
        }
    }

    return submissionResult;
}

const createRequestedPairing = logRejectionsInDev(
    async (jobId: number, providerId: number, jobType: string, submissionResult: JobSubmissionResult) => {
        await createProviderPairing(jobId, providerId, true);

        submissionResult.providerWasRequested = true;
    },
);

async function createProviderPairing(jobId: number, providerId: number, isRequested: boolean) {
    await client('api/pairings/create_from_job_request/', {
        body: {
            provider_id: providerId,
            ongoing_request_id: jobId,
            requested: isRequested,
        },
    });
}

function getWorkingHours(jobOptions: JobOptions) {
    const selectedDaysOfWeek = Object.keys(jobOptions.daysOfTheWeek) as (keyof DayOfWeekSelections)[];

    // The reason we check for sameTimes here is because the user is able to select the 'same times' option
    // In the UI and also individual times for each day. If they select the same times option, we default to
    // using the start and end_time rather than the individual times for each day.
    const dayOfWeekObjects = selectedDaysOfWeek
        .map((dayName: keyof DayOfWeekSelections) => ({
            day: dayName,
            // eslint-disable-next-line security/detect-object-injection
            start: jobOptions.sameTimes ? jobOptions.start : jobOptions.daysOfTheWeek[dayName].start,
            // eslint-disable-next-line security/detect-object-injection
            end: jobOptions.sameTimes ? jobOptions.end_time : jobOptions.daysOfTheWeek[dayName].end,
            // eslint-disable-next-line security/detect-object-injection
            slots: jobOptions.daysOfTheWeek[dayName].slots,
        }))
        .filter((hoursForDay) => jobOptions.daysOfTheWeek[hoursForDay.day].selected);

    return dayOfWeekObjects;
}

function getOngoingTimesOfDay(workingHoursByDay: SelectedDay[]) {
    return workingHoursByDay.map(timeSpanObjectForDay);
}

function timeSpanObjectForDay(hoursForDay: SelectedDay) {
    return {
        [hoursForDay.day]: {
            start: hoursForDay.start.format('HH:mm:ss'),
            end: hoursForDay.end.format('HH:mm:ss'),
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            slots: hoursForDay.slots,
        },
    };
}

export function createOngoingRequest(
    daysOfWeek: string[],
    timesOfDay: WorkingHoursForDay[],
    jobOptions: JobOptions,
    payMin: number,
    payMax: number,
    familyId: number,
    acceptedTandemPayAgreement: boolean | undefined,
    jobTypeId: number,
    jobType: string,
    status: string,
) {
    const start = jobOptions.start ? moment(jobOptions.start, 'ddd MMM DD YYYY HH:mm:ss').format('YYYY-MM-DD') : null;
    const end = jobOptions.end ? moment(jobOptions?.end, 'ddd MMM DD YYYY HH:mm:ss').format('YYYY-MM-DD') : null;

    const payIsRange = payMin !== payMax;
    const payIsLumpSum = false;

    let requestType = 'CHILD_CARE';
    if (jobTypeId === JobTypeIds.PetCare) {
        requestType = 'PET_CARE';
    } else if (jobTypeId > JobTypeIds.PetCare) {
        requestType = 'OTHER';
    }

    const ongoing = {
        family: familyId,
        start_date: jobOptions?.startEstimate !== 'OTHER' ? start : null,
        end_date: jobOptions?.startEstimate !== 'OTHER' ? end : null,
        last_hire_offset_minutes: jobOptions.lastHireOffsetMinutes,
        pay: payIsRange ? null : payMin,
        pay_is_fixed: payIsLumpSum,
        rate_min: payIsRange ? payMin : null,
        rate_max: payIsRange ? payMax : null,
        transportation: jobOptions.transportation,
        multiple_providers: jobOptions.multipleProviders,
        status: status,
        quick_fill: false,
        family_comments: jobOptions.comments,
        days_of_week: daysOfWeek,
        preferred_sitters: convertPreferredSitterObject(
            jobOptions.selectedPastWorkers,
            jobOptions.selectedPreferredWorkers,
        ),
        times_of_day: timesOfDay,
        start_month: jobOptions.startEstimate === 'OTHER' ? jobOptions.startEstimateMonth : null,
        ongoing: jobType !== 'onetime',
        headline: jobOptions?.headline || null,
        request_type: requestType,
        job_type: jobTypeId,
        accepted_tandem_pay_agreement: acceptedTandemPayAgreement,
        pay_window: jobOptions.paymentSchedule?.payWindow || 'DAILY',
        qualification_requirements: jobOptions.jobQualifications,
        address: jobOptions.address,
        business_location: jobOptions.businessLocation,
        pay_type: jobOptions.pay_type,
        business_job_type: jobOptions.business_job_type,
        pay_range_info: jobOptions.pay_range_info,
        job_details: jobOptions.jobDetails,
        pay_scales: jobOptions.payScales.filter((x: any) => x.description),
        break_required: jobOptions.breakRequired,
        break_length: jobOptions.breakLength,
        slots_available: jobOptions.slotsAvailable,
        parent_request: jobOptions.parentRequest,
        trial_run_coverage: jobOptions.trialRunCoverage,
        faqs: jobOptions.faqs,
        break_required_every_n_minutes: jobOptions.breakEveryNMinutes,
        trial_run_benefits: jobOptions.trialRunBenefits,
    };
    return ongoing;
}

function submitOngoingRequest(request: object, id: number) {
    return client(`api/ongoing/${id ? id : ''}`, {
        method: id ? 'PATCH' : 'POST',
        body: request,
    }).catch(consoleLogInDev);
}

async function submitPromoCode(code: string) {
    try {
        const body = { code: code };
        await client(`api/promo-code/apply/`, { body });
    } catch (error) {
        consoleLogInDev(error);
    }
}
