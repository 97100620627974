import moment from 'moment';
import { IJobDetails } from './types';
import { JobFormPresets } from 'parent-portal/forms/JobFormPresets';
import { formatTimesOfDay, timesAreEqual, getStartTime, getEndTime } from './timeFormatUtils';

export default function draftPresetMap(draft: IJobDetails): JobFormPresets {
    return {
        startMonth: draft.start_month,
        startDate: draft.start_date ? moment(draft.start_date) : null,
        endDate: draft.end_date ? moment(draft.end_date) : null,
        daysOfTheWeek: formatTimesOfDay(draft),
        frequency: draft.ongoing ? 'recurring' : 'onetime',
        jobType: draft.job_type_id,
        headline: draft.headline,
        description: draft.family_comments,
        pastProviders: draft.preferred_sitters,
        draftJobId: draft.id,
        simple: false,
        jobTimes: {
            start: getStartTime(draft),
            end: getEndTime(draft),
            slots: draft.times_of_day?.length ? draft.times_of_day[0].slots : draft.slots_available ?? 1,
        },
        pay: {
            payMin: draft.rate_min,
            payMax: draft.rate_max,
            isFixedPay: draft.pay_is_fixed,
            payFixed: draft.pay,
        },
        sameTimes: timesAreEqual(draft),
        jobQualifications: draft.qualification_requirements,
        businessJobType: draft.business_job_type,
        payType: draft.pay_type,
        jobDetails: draft.details,
        breakRequired: draft.break_required,
        breakLength: draft.break_length,
        slotsAvailable: draft.slots_available,
        payScales: draft.pay_scales,
        addMinimumBreak: draft.break_required_every_n_minutes ? true : false,
        breakEveryNHours: draft.break_required_every_n_minutes
            ? Math.floor(draft.break_required_every_n_minutes / 60)
            : null,
        trialRunBenefits: draft.trial_run_benefits,
        lastHireOffsetMinutes:
            draft.last_hire_offset_minutes || draft.last_hire_offset_minutes === 0
                ? draft.last_hire_offset_minutes
                : 240,
    };
}
