import React from 'react';
import { BusinessJobWithAppointments } from 'models';
import { Map } from '@mui/icons-material';
import { IconButton } from 'library';

export default function ShowInMap({ job }: { job: BusinessJobWithAppointments }) {
    const url = window.location.origin;
    const goToMap = () =>
        window.open(
            `${url}/adminperson/region-map?businessLocation=${job.address.business_location?.id}&region=${job.region}&latitude=${job.address.latitude}&longitude=${job.address.longitude}`,
        );
    return (
        <IconButton tooltip="Show this job in the region map" icon={Map} onClick={goToMap} style={{ marginLeft: 8 }} />
    );
}
