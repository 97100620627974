import { Colors, Paper, Text, CategoryCard, PrimaryButton } from 'library';
import React, { ReactNode } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { IDashboardSectionProps } from '../Models/DashboardData';

interface IProps extends IDashboardSectionProps {
    children?: ReactNode | ReactNode[];
}

const DashboardSection = ({ header, description, children }: IProps) => {
    const classes = useStyles();

    return (
        <Grid item xs={12} style={{ padding: 5 }}>
            <Paper
                style={{
                    backgroundColor: Colors.white70percent,
                    fontSize: 40,
                    lineHeight: '20px',
                    marginBottom: 20,
                }}
                padding={15}
            >
                <Grid container direction="column" alignItems="flex-start" className={classes.headerContainer}>
                    <Text variant="h1" color={Colors.darkNavy}>
                        {header}
                    </Text>
                    <Text variant="body1" textStyle={{ marginTop: description ? 10 : 0 }}>
                        {description}
                    </Text>
                </Grid>
                {children}
            </Paper>
        </Grid>
    );
};

export default DashboardSection;

const useStyles = makeStyles(() => ({
    headerContainer: {
        marginBottom: 10,
    },
}));
