import React, { useState } from 'react';
import { Colors, Checkbox, TextButton } from 'library';
import { LeanUserWithPhone, SubJobAppointment, SubJobPairing, SubJobStaff } from 'models';
import { client, consoleLogInDev } from 'shared';
import ProviderDetailsOutreachModal from 'internal/texting/outreach/ProviderDetailsOutreachModal';
import RemoveProviderModal from './RemoveProviderModal';
import {
    CheckCircle,
    HistoryToggleOff,
    Paid,
    ThumbUp,
    WatchLater,
    Email,
    Visibility,
    VisibilityOff,
} from '@mui/icons-material';
import ProviderForSubJobDropdown from './ProviderForSubJobDropdown';
import ProviderExternalMessaging from 'internal/providers/ProviderExternalMessaging';
import { TimestampModal } from 'internal/payments/HourReportTable/TimestampModal';
import { HourReportTimestamp } from 'internal/payments/HourReportModels';

export default function ProviderForSubJob({
    appt,
    businessLocationId,
    refreshJobs,
    updateFlag,
}: {
    appt: SubJobAppointment;
    businessLocationId?: number;
    updateFlag?: (staffId: number, businessLocationId: number) => void;
    refreshJobs: () => void;
}) {
    const [tooltipVisible, setTooltipVisible] = useState(-1);
    const [modalUserId, setModalUserId] = useState(-1);
    const [removeProviderUser, setRemoveProviderUser] = useState<LeanUserWithPhone>();
    const [isManageShiftOpen, setIsManageShiftOpen] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [checked, setChecked] = useState(false);
    const [dropdownAnchor, setDropdownAnchor] = useState<HTMLElement | null>(null);
    const [selectedTimestamps, setSelectedTimestamps] = useState<HourReportTimestamp[]>();

    const handleCheckBoxChange = () => {
        setChecked(!checked);
        setIsDisabled(!isDisabled);
    };

    const toggleFlag = (staff?: SubJobStaff) => {
        handleCheckBoxChange();
        const body = { bci_fbi_submitted: !staff?.bci_fbi_submitted, business_location_id: businessLocationId };

        if (staff && updateFlag) {
            client(`api/staff/${staff.id}`, {
                method: 'PATCH',
                body,
            })
                .then((resp) => updateFlag(resp.id, resp.business_location.id))
                .catch(consoleLogInDev)
                .finally(() => handleCheckBoxChange());
        }
    };

    const getBackgroundColor = (pairing: SubJobPairing) => {
        const { hour_report, provider_confirmed_at } = pairing;

        if (hour_report?.paid) {
            return Colors.lightGrey;
        } else if (hour_report?.end) {
            return Colors.lightTerraCotta;
        } else if (hour_report?.start) {
            return Colors.lightGreen;
        } else if (provider_confirmed_at) {
            return Colors.mediumTurq;
        } else {
            return Colors.white;
        }
    };

    const getIcons = (pairing: SubJobPairing, compliant: boolean) => {
        return (
            <>
                {compliant && <CheckCircle fontSize="small" htmlColor="#18C12F" />}
                {pairing.provider_confirmed_at && <ThumbUp fontSize="small" htmlColor={Colors.turquoise} />}
                {pairing.hour_report?.start ? <WatchLater fontSize="small" /> : null}
                {pairing.hour_report?.end ? <HistoryToggleOff fontSize="small" htmlColor={Colors.terraCotta} /> : null}
                {pairing.hour_report?.paid ? <Paid fontSize="small" htmlColor={Colors.mediumGrey} /> : null}
                {pairing.last_notified ? <Email fontSize="small" /> : null}
                {pairing.hired_notification_viewed ? (
                    <Visibility fontSize="small" htmlColor={Colors.darkNavy} />
                ) : (
                    <VisibilityOff fontSize="small" htmlColor={Colors.mediumRed} />
                )}
            </>
        );
    };

    return (
        <>
            {appt.pairings.map((pairing: SubJobPairing, i) => {
                const user = pairing.hired_provider;
                const staff = pairing.staff;
                if (user && staff) {
                    return (
                        <div
                            key={pairing.id}
                            onMouseEnter={(e) => {
                                setDropdownAnchor(e.currentTarget);
                                setTooltipVisible(i);
                            }}
                            onMouseLeave={() => {
                                setDropdownAnchor(null);
                                setTooltipVisible(-1);
                            }}
                        >
                            <div
                                style={{
                                    padding: 5,
                                    backgroundColor: getBackgroundColor(pairing),
                                }}
                            >
                                <div style={{ flexDirection: 'row', display: 'flex' }}>
                                    <ProviderExternalMessaging user={user} />
                                    <TextButton
                                        onClick={() => setModalUserId(user?.id || -1)}
                                        textStyle={{
                                            fontWeight: 'bold',
                                            fontSize: 14,
                                            textAlign: 'left',
                                            marginLeft: 8,
                                        }}
                                    >
                                        {user.first_name} {user.last_name} ({user.id}){' '}
                                        {pairing.muted ? '(DELETED)' : ''}
                                        {getIcons(pairing, Boolean(staff.bci_fbi_submitted))}
                                    </TextButton>
                                </div>
                                <TextButton
                                    onClick={() => setRemoveProviderUser(user)}
                                    textStyle={{ fontSize: 14, textAlign: 'left' }}
                                >
                                    Remove Provider
                                </TextButton>
                                <TextButton
                                    onClick={() => {
                                        setIsManageShiftOpen(true);
                                        setSelectedTimestamps(pairing.hour_report?.timestamps ?? []);
                                    }}
                                    textStyle={{ fontSize: 14, textAlign: 'left' }}
                                >
                                    Manage Shift
                                </TextButton>
                                <ProviderDetailsOutreachModal
                                    providerUserId={modalUserId}
                                    onClose={() => setModalUserId(-1)}
                                    open={modalUserId > 0}
                                />
                                <RemoveProviderModal
                                    providerUser={removeProviderUser}
                                    onClose={() => setRemoveProviderUser(undefined)}
                                    open={!!removeProviderUser}
                                    appointment={appt}
                                    refreshJobs={refreshJobs}
                                />
                                <TimestampModal
                                    isOpen={isManageShiftOpen}
                                    onClose={() => setIsManageShiftOpen(false)}
                                    appointmentId={appt.id}
                                    selectedTimestamps={selectedTimestamps}
                                    setSelectedTimestamps={setSelectedTimestamps}
                                    onComplete={() => {
                                        setSelectedTimestamps(undefined);
                                        refreshJobs();
                                    }}
                                    providerUserId={user.id}
                                />
                                {!staff.bci_fbi_submitted && (
                                    <Checkbox
                                        label="Background Check Submitted"
                                        checked={!!staff.bci_fbi_submitted || checked}
                                        onChange={() => toggleFlag(staff)}
                                        disabled={isDisabled}
                                    />
                                )}
                            </div>
                            <ProviderForSubJobDropdown
                                visible={tooltipVisible === i}
                                appt={appt}
                                pairing={pairing}
                                anchorEl={dropdownAnchor}
                            />
                        </div>
                    );
                }
                return <></>;
            })}
        </>
    );
}
