import React, { useState } from 'react';
import { PrimaryButton, Text, TextArea } from 'library';
import { Provider, Job } from 'models';
import { client, consoleLogInDev } from 'shared';
import { useWidth } from '../../../../../reusableComponents/useWidth';

interface IProps {
    providers: Provider[];
    job?: Job;
    setSentScreen: (isSent: boolean) => void;
    location?: string;
}

export default function MessageForm({ providers, job, setSentScreen, location }: IProps) {
    const provider = providers.length === 1 ? providers[0] : undefined;
    const defaultMessage = `Hello${
        provider ? `, ${provider.user.first_name}` : ''
    }! I think you could be a good fit for my job. Please review the details and apply if you're interested. Thanks, I look forward to hearing from you!`;

    const width = useWidth();
    const [message, setMessage] = useState(defaultMessage);
    const [isSending, setIsSending] = useState(false);

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        setMessage(event.target.value);
    }

    function sendMessage() {
        setIsSending(true);
        client(`api/invitation/`, {
            body: {
                invited_users: providers.map((p) => p.user.id),
                body: message,
                job_request: job!.id,
            },
        })
            .then((res) => {
                trackInvitationSource(res.id);
                setSentScreen(true);
            })
            .catch(consoleLogInDev)
            .finally(() => setIsSending(false));
    }

    function trackInvitationSource(id: number) {
        client(`tracking/api/invitation-source/`, {
            body: {
                source: location,
                invitation: id,
            },
        });
    }

    return (
        <div>
            <Text variant="display">
                Invite{' '}
                {provider ? `${provider.user.first_name} ${provider.user.last_name}` : `${providers.length} providers`}{' '}
                to apply!
            </Text>
            <Text variant="body2" textStyle={{ marginTop: 30 }}>
                Providers are{' '}
                <Text variant="body2" inline bold>
                    60% more likely to apply
                </Text>{' '}
                when they receive an invitation that includes a message.
            </Text>
            <Text variant="body2" textStyle={{ marginTop: 6 }}>
                The details of your job will be sent with your message.
            </Text>
            <Text variant="caption" textStyle={{ marginTop: 20 }}>
                Message
            </Text>
            <TextArea
                id="outlined-multiline-static"
                multiline
                rows={width === 'xs' ? 10 : 7}
                value={message}
                variant="outlined"
                style={{ width: '100%', marginTop: 4 }}
                onChange={handleChange}
            />
            <div style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'row', marginTop: 30 }}>
                <div style={{ width: 200 }}>
                    <PrimaryButton
                        loading={isSending}
                        onClick={sendMessage}
                        disabled={message.trim().length === 0 || isSending}
                    >
                        Send
                    </PrimaryButton>
                </div>
            </div>
        </div>
    );
}
