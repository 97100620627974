import { ApiBaseUrl } from 'environmentVariables';

function addTrailingSlash(endpoint: string) {
    if (endpoint.includes('?')) {
        return endpoint;
    } else if (endpoint.charAt(endpoint.length - 1) !== '/') {
        return `${endpoint}${'/'}`;
    }
    return endpoint;
}

interface StreamRequest {
    endpoint: string;
    token?: string;
    onYield: (chunk: string) => void;
    onError: (error: Error) => void;
    onComplete: () => void;
}

const streamRequest = ({ endpoint, token, onYield, onError, onComplete }: StreamRequest) => {
    const updatedEndpoint = addTrailingSlash(endpoint);

    const finalToken = token || localStorage.getItem('token') || '';

    return fetch(`${ApiBaseUrl}/${updatedEndpoint}`, {
        method: 'GET',
        headers: { 'content-type': 'text/event-stream', Authorization: `Token ${finalToken}` },
    })
        .then((response) => {
            const reader = response?.body?.getReader();
            const decoder = new TextDecoder();

            const readStream = () => {
                reader
                    ?.read()
                    .then(({ done, value }) => {
                        if (done) {
                            onComplete();
                            reader.cancel();
                            return;
                        }
                        const valAsStr = value ? decoder.decode(value, { stream: true }) : '';
                        if (valAsStr.includes('Stream complete')) {
                            onComplete();
                            reader.cancel();
                            return;
                        } else {
                            onYield(valAsStr);
                            readStream();
                        }
                    })
                    .catch((error) => {
                        onError(error);
                        reader.cancel();
                    });
            };
            readStream();
        })
        .catch((error) => {
            onError(error);
        });
};

export default streamRequest;
