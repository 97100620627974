import moment from 'moment';
import {
    IPayFormFields,
    JobPaymentTypes,
    PaymentSchedule,
    ProviderSelection,
    RequestType,
    StartEstimateMonthType,
    TemplateOption,
} from './JobRequestTypes';

const dayField = {
    selected: false,
    start: null as moment.Moment | null,
    end: null as moment.Moment | null,
    slots: 1,
};

const ongoingFormFields = {
    startEstimateMonth: null as StartEstimateMonthType,
    daysOfTheWeek: {
        Monday: { ...dayField, day: 1 },
        Tuesday: { ...dayField, day: 2 },
        Wednesday: { ...dayField, day: 3 },
        Thursday: { ...dayField, day: 4 },
        Friday: { ...dayField, day: 5 },
        Saturday: { ...dayField, day: 6 },
        Sunday: { ...dayField, day: 7 },
    },
    sameTimes: true,
    longTermJob: true,
    multipleProviders: false,
    paymentSchedule: undefined as PaymentSchedule | undefined,
};

const oneTimeFormFields = {
    jobTimes: {
        start: null as moment.Moment | null,
        end: null as moment.Moment | null,
        slots: 1,
    },
    isQuickFill: false as boolean | null,
};

const commonFormFields = (isBusiness: boolean) => {
    const payMin = isBusiness ? 13 : 19;
    const payMax = isBusiness ? 15 : 24;

    return {
        startDate: moment() as moment.Moment | null,
        endDate: moment().add(28, 'days') as moment.Moment | null,
        lastHireOffsetMinutes: 240,
        comments: '',
        headline: '',
        requestType: '' as RequestType,
        pay: {
            payMin,
            payMax,
            payFixed: null,
            isFixedPay: null,
        } as IPayFormFields,
        transportation: false as boolean | null,
        preferredWorkers: [] as ProviderSelection[],
        pastWorkers: [] as ProviderSelection[],
        providerType: { sitter: true, tutor: false, teacher: false, nanny: false },
        jobTypeId: 1,
        otherJobType: '',
        userEmail: '',
        zipCode: '',
        acceptedTandemPayAgreement: undefined as boolean | undefined,
        code: '',
        businessJobType: undefined,
        payType: isBusiness ? ('PERCENTAGE' as JobPaymentTypes) : null,
        jobQualifications: [],
        address: [],
        jobDetails: [],
        payScales: Array.from(Array.from({ length: payMax - payMin + 1 }), (x, i) => {
            return { rate: i + payMin, description: '', qualifications: [] };
        }),
        breakRequired: false,
        breakLength: undefined,
        slotsAvailable: 1,
        useTemplate: TemplateOption.NEW,
        templateJobId: undefined,
        trialRunCoverage: false,
        faqs: [],
        requireMinimumQualifications: false,
        addMinimumBreak: false,
        breakEveryNHours: null,
        trialRunBenefits: undefined,
    };
};

export const initialForm = (isBusiness: boolean) => ({
    ...commonFormFields(isBusiness),
    ...oneTimeFormFields,
    ...ongoingFormFields,
});
