import React, { useState } from 'react';
import { Grid, styled } from '@mui/material';
import OpenInNew from '@mui/icons-material/OpenInNew';
import { AdminBusinessLocation } from 'models/AdminBusinessLocation';
import { Colors, Link, Text } from 'library';
import { addressToString } from 'internal/business/maps/distances';
import BusinessLocationModal from 'internal/business-location/BusinessLocationModal';
import { formatMonthDayYearWith12HourTime } from 'shared/Dates';
import Notes from 'internal/shared/tables/Notes';
import StatusChip from './StatusChip';
import ImpersonateButton from 'internal/impersonate/ImpersonateButton';
import Label from './Label';
import PhoneDialog from './PhoneDialog';
import { useBusinessLocationsContext } from './BusinessLocationsContext';
import { statusColor, statusTextColor, staticStatusColor } from './utilities';
import LastNoteLabel from './LastNoteLabel';
import OpenTasksBadge from './OpenTasksBadge';
import AddTaskDialog from './AddTaskDialog';
import { formatCurrency } from 'shared';
import JobThisWeekChip from './JobThisWeekChip';
import JobNextWeekChip from './JobNextWeekChip';

interface BusinessLocationListItemProps {
    businessLocation: AdminBusinessLocation;
    position: 'top' | 'middle' | 'bottom' | 'single';
}

export default function BusinessLocationListItem({ businessLocation, position }: BusinessLocationListItemProps) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { statusColors, staticStatuses, loading } = useBusinessLocationsContext();
    const [initialTab, setInitialTab] = useState<string>();

    function getRevenueString(revenue?: number) {
        return !revenue ? 'N/A' : formatCurrency(revenue, 0);
    }

    if (loading) return null;

    return (
        <StyledListItem className={position}>
            <Container container>
                <Grid item md={3}>
                    <Grid>
                        <OpenTasksBadge
                            businessLocation={businessLocation}
                            openModal={() => {
                                setIsModalOpen(true);
                                setInitialTab('Tasks');
                            }}
                        >
                            <Link
                                onClick={() => setIsModalOpen(true)}
                                style={{ paddingLeft: businessLocation.open_tasks ? 30 : 0 }}
                            >
                                {businessLocation.name} ({businessLocation.id}){' '}
                                {(businessLocation.overdue_invoices || 0) > 0 ? '💸' : ''}
                            </Link>
                        </OpenTasksBadge>
                    </Grid>
                    <Grid>
                        <a
                            href={`/adminperson/business-location/${businessLocation.id}`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <StyledOpenInNew />
                        </a>
                        <IconButtonContainer>
                            <ImpersonateButton email={businessLocation.business.user.email} />
                        </IconButtonContainer>
                        <IconButtonContainer>
                            <PhoneDialog businessLocationId={businessLocation.id} name={businessLocation.name} />
                        </IconButtonContainer>
                        <IconButtonContainer>
                            <AddTaskDialog businessLocation={businessLocation} />
                        </IconButtonContainer>
                    </Grid>

                    <Text variant="caption">{addressToString(businessLocation.address)}</Text>
                    <Text variant="caption">
                        <b>Primary User: </b>
                        {businessLocation.business.user.first_name} {businessLocation.business.user.last_name} (
                        {businessLocation.business.user.id})
                    </Text>
                    <Text variant="caption">
                        <b>Business: </b>
                        {businessLocation.business.name}
                    </Text>
                    <Text variant="caption">
                        <b>Capacity: </b>
                        {businessLocation.capacity}
                    </Text>
                    {businessLocation.distance || businessLocation.distance === 0 ? (
                        <Text variant="caption">
                            <b>Distance to user: </b>
                            {businessLocation.distance} mile{businessLocation.distance === 1 ? '' : 's'}
                        </Text>
                    ) : null}
                </Grid>
                <Grid item md={3}>
                    <Text variant="caption">
                        <b>Sign Up: </b>
                        {formatMonthDayYearWith12HourTime(businessLocation.business.added_at)}
                    </Text>
                    <Text variant="caption">
                        <b>Last login: </b>
                        {formatMonthDayYearWith12HourTime(businessLocation.last_login || businessLocation.added_at)}
                    </Text>
                    <Text variant="caption">
                        <b>Last called: </b>
                        {businessLocation.last_called
                            ? formatMonthDayYearWith12HourTime(businessLocation.last_called)
                            : ''}
                    </Text>
                    <Text variant="caption">
                        <b>Last emailed: </b>
                        {businessLocation.last_emailed
                            ? formatMonthDayYearWith12HourTime(businessLocation.last_emailed)
                            : ''}
                    </Text>
                    <Text variant="caption">
                        <b>Total Centers: </b>
                        {businessLocation.businesslocationaggregates?.related_location_count || '1'}
                    </Text>
                    <Text variant="caption">
                        <b>30 Day Rev: </b>
                        {getRevenueString(businessLocation.businesslocationaggregates?.past_thirty_day_revenue)}
                    </Text>
                    <Text variant="caption">
                        <b>All-Time Rev: </b>
                        {getRevenueString(businessLocation.businesslocationaggregates?.all_time_revenue)}
                    </Text>
                </Grid>
                <Grid item md={3}>
                    <StatusContainer>
                        <Label label="Cust. Status" />
                        <StatusChip
                            text={businessLocation.status?.name || 'Unknown'}
                            color={statusColor(statusColors, businessLocation.status?.id)}
                            textColor={statusTextColor(statusColors, businessLocation.status?.id)}
                            title={businessLocation.status?.description}
                        />
                        {businessLocation.is_reengaged && (
                            <StatusChip text="Re-Engaged" color={staticStatusColor(staticStatuses, 'Reengaged')} />
                        )}
                        {businessLocation.inactive_with_new_job_posted && (
                            <StatusChip text="New Post" color={staticStatusColor(staticStatuses, 'Inactive New Job')} />
                        )}
                        {businessLocation.active_at_risk && (
                            <StatusChip
                                text="At Risk"
                                color={staticStatusColor(staticStatuses, 'At Risk - Inactive')}
                            />
                        )}
                    </StatusContainer>
                    <StatusContainer>
                        <Label label="Job Status" />
                        {businessLocation.has_job_current_week && <JobThisWeekChip />}
                        {businessLocation.has_job_next_week && <JobNextWeekChip />}
                    </StatusContainer>
                    {businessLocation.snooze_until && (
                        <Text variant="caption">
                            <b>Snoozed until: </b> {formatMonthDayYearWith12HourTime(businessLocation.snooze_until)}
                        </Text>
                    )}
                </Grid>
                <Grid item md={3}>
                    <Text variant="caption">
                        <LastNoteLabel note={businessLocation.last_note} />
                        {businessLocation.last_note?.text}
                        <Notes
                            tooltip="Add a note to this business location"
                            name={businessLocation.name}
                            type="business-location"
                            objectId={businessLocation.id}
                        />
                    </Text>
                </Grid>
            </Container>
            {isModalOpen ? (
                <BusinessLocationModal
                    businessLocationId={businessLocation.id}
                    open={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    initialTab={initialTab}
                />
            ) : null}
        </StyledListItem>
    );
}

const Container = styled(Grid)({
    padding: 5,
});

const StyledListItem = styled('li')({
    marginBottom: 10,
    listStyleType: 'none',
    '&.top': { borderLeft: `8px solid ${Colors.turquoise};`, marginBottom: 0, paddingBottom: 10 },
    '&.bottom': { borderLeft: `8px solid ${Colors.turquoise};` },
    '&.middle': { borderLeft: `8px solid ${Colors.turquoise};`, marginBottom: 0, paddingBottom: 10 },
});

const StyledOpenInNew = styled(OpenInNew)({
    color: Colors.darkNavy,
    height: 20,
    marginLeft: 3,
});

const StatusContainer = styled('div')({
    padding: '5px 0',
});

const IconButtonContainer = styled('span')({
    margin: '0 3px ',
});
