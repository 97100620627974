import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Grid } from '@material-ui/core';
import { Text, SizeableRoundedDialog, PrimaryButton, OutlinedDropdown, Colors, SecondaryButton } from 'library';
import { HourReportTimestamp, IHourReport } from '../HourReportModels';
import { TimestampDisplay } from './TimestampDisplay';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { approveReport, clearBreak, getTimestampsByPriority } from 'parent-portal/subs/HourReportMethods';
import { TimestampRow } from './TimestampRow';
import { differenceInMinutes, subDays } from 'date-fns';
import { client, consoleLogInDev } from 'shared';
import { formatDateFromDateObject } from 'shared/Dates';
import ReportSummary from './ReportSummary';
import { IShift, ShiftProvider } from 'models';

export const orderedTimestampNames = [
    'clock_in',
    'altered_clock_in',
    'clock_out',
    'altered_clock_out',
    'break_start',
    'altered_break_start',
    'break_end',
    'altered_break_end',
];

const userRoles = ['provider', 'business', 'admin'];

export interface UpdatedTimes {
    [key: string]: Date | undefined;
}

export function TimestampModal({
    isOpen,
    onClose,
    selectedTimestamps,
    setSelectedTimestamps,
    onComplete,
    appointmentId,
    providerUserId,
    manualReason,
    manualReasonComment,
}: {
    isOpen: boolean;
    onClose: () => void;
    selectedTimestamps: HourReportTimestamp[] | undefined;
    setSelectedTimestamps: (h: HourReportTimestamp[] | undefined) => void;
    onComplete: () => void;
    appointmentId?: number;
    providerUserId?: number;
    manualReason?: string;
    manualReasonComment?: string;
}) {
    const [updatedTimes, setUpdatedTimes] = useState<UpdatedTimes>({});
    const [selectedAppointmentId, setSelectedAppointmentId] = useState<number | undefined>(appointmentId);
    const [availableAppointments, setAvailableAppointments] = useState<IShift[]>([]);

    const additionalTimestamps = selectedTimestamps?.filter(
        (t) =>
            !orderedTimestampNames.some((n) => t.time_type.type.includes(n) && !t.time_type.type.startsWith('manual')),
    );

    useEffect(() => {
        if (!isOpen) {
            return;
        }

        if (!appointmentId && selectedTimestamps?.length === 0) updateAvailableAppointments();
    }, [appointmentId, selectedTimestamps, isOpen]);

    function updateAvailableAppointments() {
        client(
            `api/sub-shifts/upcoming-shifts/?user_id=${providerUserId}&start=${formatDateFromDateObject(
                subDays(new Date(), 1),
            )}&end=${formatDateFromDateObject(new Date())}`,
        )
            .then((res) => {
                setAvailableAppointments(res);
                if (res.length === 1) {
                    selectAppointment(res[0].id.toString());
                }
            })
            .catch(consoleLogInDev);
    }

    function formatAppointments(res: IShift[]) {
        return res.reduce(
            (c: { key: string; value: string }[], n: IShift) => {
                const value = `${n.ongoing_request.address.description}: ${
                    n.ongoing_request.headline
                } - ${formatDateFromDateObject(new Date(n.start_date))}`;
                const key = `${n.id}`;
                return c.concat({ key, value });
            },
            [{ key: '0', value: 'Select Shift to manage' }],
        );
    }

    function selectAppointment(shiftId: string) {
        if (shiftId === '0') setSelectedAppointmentId(undefined);
        const shift = availableAppointments.find((a) => a.id === parseInt(shiftId));
        const provider = shift?.providers.find((p: ShiftProvider) => p.provider.id === providerUserId);
        if (shift && provider) {
            const hour_report = shift.hour_reports?.find((h: IHourReport) => h.provider.id === provider?.provider_id);
            setSelectedTimestamps(hour_report?.timestamps ?? []);
            setSelectedAppointmentId(shift.id);
        }
    }

    function updateTimestamps(d: MaterialUiPickersDate | Date, accessor: string) {
        setUpdatedTimes({ ...updatedTimes, [accessor]: d as Date });
    }

    function updateAdminTimes(role: string) {
        const adminTimes = orderedTimestampNames.reduce((c: UpdatedTimes, n: string) => {
            let value = selectedTimestamps?.find((t) => t.time_type.type === `${role}_${n}`)?.reported_at;
            if (n.includes('altered') && role === 'provider') {
                n = n.replace('altered_', '');
            } else if (n.includes('altered')) {
                return c;
            }

            if (!value && selectedTimestamps) {
                const timestamp = getTimestampsByPriority(selectedTimestamps, [
                    `admin_${n}`,
                    `business_${n}`,
                    `provider_altered_${n}`,
                    `provider_${n}`,
                ]);
                value = timestamp?.reported_at;
            }
            if (value) c[`admin_${n}`] = new Date(value);
            return c;
        }, {});
        setUpdatedTimes(adminTimes);
    }

    function submit(times: UpdatedTimes) {
        const report_id = selectedTimestamps?.length ? selectedTimestamps[0].hour_report : 0;
        approveReport(report_id, times, selectedAppointmentId, providerUserId)
            .then(onComplete)
            .finally(close)
            .catch(consoleLogInDev);
    }

    function submitChanges() {
        setSelectedTimestamps(undefined);
        submit(updatedTimes);
    }
    function onClearBreak() {
        const report_id = selectedTimestamps?.length ? selectedTimestamps[0].hour_report : 0;
        clearBreak(report_id).then(onComplete).catch(consoleLogInDev).finally(close);
    }

    function resolve() {
        const times = { ...updatedTimes, admin_resolved: new Date() };
        submit(times);
        setSelectedTimestamps(undefined);
    }

    function close() {
        onClose();
        setSelectedTimestamps(undefined);
        setUpdatedTimes({});
        updateAvailableAppointments();
        setSelectedAppointmentId(undefined);
    }

    function activeTimeByPriority(priority: string[]) {
        const provider_timestamp = getTimestampsByPriority(
            selectedTimestamps ?? [],
            priority.map((p) => `provider_${p}`),
        );

        const admin_timestamp = getTimestampsByPriority(
            selectedTimestamps ?? [],
            priority.map((p) => `admin_${p}`),
        );

        const providerUpdatedTimes = priority.map((p) => updatedTimes[`provider_${p}`]).filter((t) => t !== undefined);
        const adminUpdatedTimes = priority.map((p) => updatedTimes[`admin_${p}`]).filter((t) => t !== undefined);

        const provider_time = providerUpdatedTimes.length ? providerUpdatedTimes[0] : provider_timestamp?.reported_at;
        const admin_time = adminUpdatedTimes.length ? adminUpdatedTimes[0] : admin_timestamp?.reported_at;

        const time = admin_time ?? provider_time;
        return time ? new Date(time) : undefined;
    }

    function startTime() {
        const startPriority = ['altered_clock_in', 'clock_in'];
        return activeTimeByPriority(startPriority);
    }

    function endTime() {
        const endPriority = ['altered_clock_out', 'clock_out'];
        return activeTimeByPriority(endPriority);
    }

    function breakStartTime() {
        const breakStartPriority = ['altered_break_start', 'break_start'];
        return activeTimeByPriority(breakStartPriority);
    }

    function breakEndTime() {
        const breakEndPriority = ['altered_break_end', 'break_end'];
        return activeTimeByPriority(breakEndPriority);
    }

    function hoursWorked() {
        const start = startTime();
        const end = endTime();
        const breakStart = breakStartTime();
        const breakEnd = breakEndTime();
        if (start && end) {
            const diff = differenceInMinutes(end, start);
            if (!breakStart || !breakEnd) return diff / 60;
            const breakDiff = differenceInMinutes(breakEnd, breakStart);
            return parseFloat(((diff - breakDiff) / 60).toFixed(2));
        }
        return 0;
    }
    const hoursLessThanZero = startTime() && endTime() && hoursWorked() <= 0;

    return (
        <SizeableRoundedDialog open={isOpen} onClose={close} closeButton>
            {availableAppointments.length ? (
                <Grid container style={{ padding: 40, gap: 10, width: 400 }} direction="column">
                    <Text bold>Select shift to manage:</Text>
                    <OutlinedDropdown
                        id="week-selection"
                        value={selectedAppointmentId || 0}
                        onChange={(e) => selectAppointment(e.target.value)}
                        fields={formatAppointments(availableAppointments)}
                    />
                </Grid>
            ) : null}
            <TableContainer style={{ padding: 40 }}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="right" style={{ width: '250px' }}>
                                User Role
                            </TableCell>
                            {orderedTimestampNames.map((n) => (
                                <TableCell align="right" key={n}>
                                    {n
                                        .split('_')
                                        .map((w) => w.charAt(0).toUpperCase() + w.slice(1))
                                        .join(' ')}
                                </TableCell>
                            ))}
                            <TableCell align="right" style={{ width: '250px' }}>
                                Actions
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {userRoles.map((row: string, index: number) => (
                            <TimestampRow
                                key={index}
                                index={index}
                                row={row}
                                selectedTimestamps={selectedTimestamps}
                                updateTimestamps={updateTimestamps}
                                updatedTimes={updatedTimes}
                                resolve={resolve}
                                updateAdminTimes={updateAdminTimes}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Grid item container direction="column" style={{ paddingRight: 40, gap: 10 }}>
                {hoursLessThanZero ? (
                    <Text bold textStyle={{ color: Colors.mediumRed, textAlign: 'right' }}>
                        Hours worked less than zero, make sure to check that your times are in the correct AM or PM.
                    </Text>
                ) : null}

                <Grid item container direction="row" justify="flex-end" style={{ gap: 40 }}>
                    <SecondaryButton onClick={onClearBreak} buttonStyle={{ width: undefined, height: 40 }}>
                        Clear Break
                    </SecondaryButton>
                    <ReportSummary
                        start={startTime()}
                        end={endTime()}
                        breakStart={breakStartTime()}
                        breakEnd={breakEndTime()}
                        hoursWorked={hoursWorked()}
                    />
                    <PrimaryButton
                        disabled={
                            Object.keys(updatedTimes).length === 0 ||
                            (availableAppointments.length > 0 && !selectedAppointmentId) ||
                            hoursLessThanZero
                        }
                        buttonStyle={{ maxWidth: 200, padding: 0, height: 40 }}
                        onClick={submitChanges}
                    >
                        Save Changes
                    </PrimaryButton>
                </Grid>
            </Grid>
            <Grid container item direction="column" style={{ gap: 20, padding: '20px 40px' }}>
                {additionalTimestamps?.length ? (
                    <>
                        <Text variant="h1">Additional Timestamps for report:</Text>
                        {additionalTimestamps.map((timestamp) => (
                            <TimestampDisplay timestamp={timestamp} key={timestamp.id} />
                        ))}
                    </>
                ) : null}
                {manualReason ? (
                    <>
                        <Text variant="body1">Manual Reason: {manualReason}</Text>
                        <Text variant="body1">Manual Reason Comment: {manualReasonComment}</Text>
                    </>
                ) : null}
            </Grid>
        </SizeableRoundedDialog>
    );
}
