import React from 'react';
import moment from 'moment';
import { Text, Colors } from 'library';
import { AdminProviderListUser } from 'models';

export default function OutreachSuspendedAlert({ user }: { user: AdminProviderListUser }) {
    return user.suspended_at ? (
        <Text variant="body2" color={Colors.error} textStyle={{ marginBottom: 5 }}>
            Suspended on {moment(user.suspended_at).format('ddd, MMM Do YYYY')} (
            {moment().diff(user.suspended_at, 'days')} days ago)
        </Text>
    ) : null;
}
