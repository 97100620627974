import { Address, LeanUserWithPhone, LeanUser } from './User';
import { UpperDaysOfWeek } from 'shared/Dates';
import { AdminProvider } from './Providers';
import { FullJobQualificationValue } from './Qualifications';
import { JobDetailValueWithOption } from './Job';
import { Note } from './Notes';
import { HourReportTimestamp } from 'internal/payments/HourReportModels';
import { AdminSlot } from './Slot';

export interface Business {
    id: number;
    name: string;
    type: number;
    type_description: string;
    needs_description: boolean;
    user: AdminBusinessUser;
    business_enabled: boolean;
    pricing_plan: PricingPlan;
    active_jobs: { [key: string]: number };
    last_note?: Note;
    link_disabled: boolean;
    business_type: 'CHILD_CARE' | 'ELDERCARE';
    signup_need?: string;
    business_faqs: IBusinessFaq[];
}

interface IBusinessFaq {
    id: number;
    business: number;
    business_location: number | null;
    question: IBusinessLocationFaq;
    answer: string;
}
export interface PricingPlan {
    description: string;
    hourly_type: 'PERCENT' | 'FIXED';
    hourly_value: number;
    hourly_max_amount: number;
    daily_posting_type: 'NONE' | 'WITH_APPLICANTS' | 'ACTIVE_JOB_POST';
    daily_posting_value: number;
    is_default: boolean;
    budget_enabled: boolean;
    trial_run_hours: number;
}

export interface LeanBusinessLocationWithAddress {
    id: number;
    address: Address;
    name: string;
}

export interface PricingPlanException {
    description: string;
    hourly_type: 'PERCENT' | 'FIXED';
    hourly_value: number;
    hourly_max_amount: number;
    business: number;
    payee: number;
}

export const defaultPricingPlan = {
    description: 'Default Business Plan',
    hourly_type: 'PERCENT',
    hourly_value: 35,
    hourly_max_amount: 6,
    daily_posting_type: 'ACTIVE_JOB_POST',
    daily_posting_value: 40,
    is_default: true,
} as PricingPlan;

export interface AdminBusiness extends Omit<Business, 'user'> {
    user: AdminBusinessUser;
    customer_service_contact?: AdminBusinessUser;
    sales_contact?: AdminBusinessUser;
    sales_credits?: LeanUser[];
    job_post_credits?: LeanUser[];
    sales_link?: string;
}

export interface AdminBusinessUser {
    id: number;
    first_name: string;
    last_name: string;
    last_login: string;
    added_at: string;
    payment_connected: boolean;
    address?: Address;
    phone_number: string;
    addresses: Address[];
    email: string;
    communication_preference?: string;
    referral_code: string;
}

export interface Staff {
    id: number;
    bci_fbi_completed: boolean;
    bci_fbi_submitted: boolean;
    confirmed: boolean;
    diploma_sent: boolean;
    medical_form_sent: boolean;
    on_staff: boolean;
    permanent: boolean;
    provider: {
        id: number;
        user: LeanUser;
    };
    business: {
        id: number;
        name: string;
    };
    business_location?: {
        id: number;
        name: string;
    };
}

export interface Applicant {
    id: number;
    user: LeanUser;
    hired: boolean;
    on_staff: boolean;
    liked: boolean;
    declined: boolean;
    bci_fbi_status: string;
    availability: string;
    last_message: { message: string; sent_at: string; sender: LeanUser; reciever: LeanUser };
    provider: AdminProvider;
}

export type JobTimesOfDay = {
    [key in UpperDaysOfWeek]: { start: string; end: string; timezone: string };
};
export interface BusinessJobSummary {
    name: string;
    number_active_jobs: number;
    has_open_sub_job: number;
    has_open_trial_run: boolean;
    number_providers_on_staff: number;
    number_workers_paid_in_past_week: number;
    last_worker_used_at: string | undefined;
    locations: IBusinessLocation[];
    user: {
        email: string;
        first_name: string;
        last_name: string;
        phone_number: string;
        id: number;
        communication_preference: string;
        address: Address;
    };
}

export interface ApprovalNeededPairing {
    babysitter: number;
    id: number;
    provider_last_minute_arrival?: string;
    status: string;
}

export interface PayScaleQualification {
    name: string;
    option?: string;
    value?: string;
    qualification_id: number;
    option_id?: number;
}
export interface BusinessJob {
    id: number;
    status: string;
    headline: string;
    added_at: string;
    start_date: string;
    end_date: string;
    start_month: string;
    admin_status: string;
    pay: number;
    rate_min: number;
    rate_max: number;
    bci_required: boolean;
    combined_check_required: boolean;
    applicants: Applicant[];
    family_comments: string;
    qualification_requirements: FullJobQualificationValue[];
    business_job_type: string;
    pay_type: string;
    pay_range_info: string;
    times_of_day: JobTimesOfDay[];
    tandem_managed: boolean;
    change_requests?: JobChangeRequest[];
    applied_days?: AppliedDays[];
    slots_available?: number;
    break_length?: number;
    pay_scales?: {
        rate: number;
        id: number;
        description: string;
        qualifications: PayScaleQualification[];
    }[];
    missing_micro_deposits?: boolean;
    outstanding_matches?: number;
    daily_schedules?: {
        day: number;
        start_time: string;
        end_time: string;
        timezone: string;
        slots: number;
        job: number;
    }[];
    require_minimum_qualifications: boolean;
}

export interface AppliedDays {
    appointment_id: number;
    start_date: string;
    end_date: string;
    pairings: ApprovalNeededPairing[];
    slots_left?: number;
}

export interface BusinessJobWithUser extends Omit<BusinessJob, 'applicants'> {
    business: { name: string; id: number }[];
    user: AdminBusinessUser;
    preferred_sitters: { preferred_sitters: { sitter: number }[] };
    is_recurring_business: boolean;
    business_location?: { id: number; name: string; address: Address; program_id: string };
    booked_days?: string[];
    user_on_probation?: boolean | null;
    last_applied_to?: string;
    created_by: LeanUser;
}

export interface BusinessJobWithAppointments extends BusinessJobWithUser {
    slots: AdminSlot[];
    address: Address;
    region: string;
    details: JobDetailValueWithOption[];
    ongoing: boolean;
    family: number;
    outstanding_matches?: number;
    business_location_program_id?: string;
    urgent?: boolean;
    closed_appointments: SubJobClosedAppointment[];
    autobook_next_review?: string;
    parent_request_id?: number;
    trial_run_coverage: boolean;
    auto_schedule_job: boolean;
    request_removed_at: string;
    request_posted_at: string;
    request_renewed_at?: string;
    is_first_job?: boolean;
}

export interface JobChangeRequest {
    id: number;
    time_changes: JobTimeChange[];
    pay: number;
    comment: string;
    bring_child: boolean;
    deleted: boolean;
    approved: boolean;
    user: number;
    ongoing_request: number;
    business_confirmed: boolean;
}

export interface JobTimeChange {
    id: number;
    start: string;
    end: string;
    deleted: boolean;
    approved: boolean;
    change_request: number;
    appointment: number;
    appointment_status: string;
}

export interface AppointmentExclusiveMatch {
    id: number;
    expires_at: string;
    accepted_at: string;
    declined_at: string;
    appointment: number;
    pay_rate: number;
    matched_worker: string;
}

export interface SubJobAppointment extends SubJobClosedAppointment {
    time_changes_requested: SubJobTimeChange[];
    pairings: SubJobPairing[];
    matches?: AppointmentExclusiveMatch[];
}

export interface SubJobClosedAppointment {
    id: number;
    start_date: string;
    end_date: string;
    status: string;
    day_of_week: UpperDaysOfWeek;
    timezone: string;
    timezone_offset: number;
}

export interface SubJobHourReport {
    id: number;
    paid: boolean;
    start: string | null;
    end: string | null;
    timestamps: HourReportTimestamp[];
}
export interface SubJobPairing {
    id: number;
    status: string;
    hired_provider: LeanUserWithPhone;
    staff: SubJobStaff;
    pay_rate: number;
    provider_confirmed_at: string;
    hired_notification_viewed: string;
    hour_report?: SubJobHourReport;
    last_notified: string;
    muted: boolean;
    hired_by: LeanUser;
}
export interface SubJobApprovedRequest extends Omit<JobChangeRequest, 'user'> {
    user: LeanUser;
}

export interface SubJobTimeChange extends Omit<JobTimeChange, 'change_request'> {
    change_request: SubJobApprovedRequest | null;
}

export interface SubJobStaff {
    id: number;
    user: number;
    business: number | null;
    business_location: number;
    on_staff: boolean;
    confirmed: boolean;
    sub: boolean | null;
    permanent: boolean | null;
    bci_fbi_submitted: boolean;
    bci_fbi_completed: boolean;
    medical_form_sent: boolean;
    diploma_sent: boolean;
}

export interface IPayMethod {
    id: number;
    is_default: boolean;
    account_type: string;
    last_four: string;
    errorMessage: string;
    isLoading: boolean;
    addresses: Address[];
}

export interface ILocationPhoto {
    id: number;
    photo: { image: string };
}
export interface ILocationAdministration {
    id: number;
    name: string;
    role: string;
    photo: { image: string };
}

export interface IBusinessLocation {
    id: number;
    address: Address;
    business: Business;
    business_contacts: IBusinessContact[];
    name: string;
    payment_information?: IPayMethod;
    program_id: string;
    customer_service_contact?: AdminBusinessUser;
    capacity?: number | null;
    website: string;
    business_faqs: IBusinessLocationFaq[];
    has_job_current_week: boolean;
    has_job_next_week: boolean;
    education_type_names: string[];
    location_photos: ILocationPhoto[];
    location_administration: ILocationAdministration[];
    has_posted_legacy_job?: boolean;
    snooze_until: string;
    snooze_reason: number;
    snooze_note_text: string;
    autobook_paused_until: string | null;
    overdue_invoices?: number;
}

interface IBusinessLocationFaq {
    id: number;
    question: number;
    answer: string;
    business: number;
    added_at: string;
    last_updated: string;
}

export interface IBusinessLocationWithNotes extends IBusinessLocation {
    notes: Note[];
    history: LocationHistory[];
    status?: string;
}

export interface LocationHistoryType {
    id: number;
    name: string;
    background_color: string;
    foreground_color: string;
    active: boolean;
    icon: string;
}
export interface LocationHistory {
    id: number;
    display: string;
    added_at: string;
    last_updated: string;
    history_type: LocationHistoryType;
}

export interface ILocationStatusHistory {
    business_location: number;
    added_at: string;
    last_updated: string;
    business_location_status: {
        id: number;
        name: string;
        description: string;
        added_at: string;
        last_updated: string;
    };
}

export interface IBusinessContact {
    id: number;
    address: number;
    business: number;
    business_location: number;
    added_at: string;
    last_updated: string;
    user: {
        email: string;
        first_name: string;
        last_name: string;
        phone_number: string;
        id: number;
        communication_preference: string;
    };
}

export interface IBusinessLocationInbox {
    business_location_id: number;
    no_show_count: number | null;
    unfilled_trial_count: number | null;
    cancellation_count: number | null;
    confirmed_no_show_count: number | null;
    business_location: IBusinessLocation;
    total: number;
}
