import React, { useState, useContext, useEffect, ReactNode } from 'react';
import client from './shared/ApiClient';
import { useHistory, useLocation } from 'react-router-dom';
import { consoleLogInDev } from 'shared';
import { UserContextUser, IRole } from 'models';
import { MixPanelKey } from 'environmentVariables';
import mixpanel from 'mixpanel-browser';

mixpanel.init(MixPanelKey);

interface IContextProps {
    children: ReactNode;
}

export interface UserContextType {
    user?: UserContextUser;
    role?: IRole;
    hasPostedJob?: boolean;
    refreshUser: (onComplete?: () => void) => Promise<UserContextUser>;
    updateHasPostedJob: () => void;
    refreshAlreadyLoggedInUser: (onComplete?: () => void) => void;
    savedProviders?: any[];
    updateSavedProviders: (providerId: number) => void;
    fetchUser: () => any;
    tempToken: string;
    setTempToken: (x: string) => void;
    getDisplayText: (key: string, fallbackValue?: string) => string;
    logout: (redirect?: string) => void;
}

export const UserContext = React.createContext<UserContextType>({} as UserContextType);

export function UserProvider({ children }: IContextProps) {
    const [user, setUser] = useState<UserContextUser>();
    const [role, setRole] = useState<IRole>();
    const [hasPostedJob, setHasPostedJob] = useState<boolean>();
    const [savedProviders, setSavedProviders] = useState<number[]>([]);
    const [tempToken, setTempToken] = useState<string>('');
    const [businessTypeTranslations, setBusinessTypeTranslations] = useState<{ [key: string]: string }>();
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        if (localStorage.getItem('token')) {
            refreshUser();
        }
    }, []);

    useEffect(() => {
        if (
            user &&
            !user.admin &&
            role !== 'marketer' &&
            !user.is_in_supported_region &&
            !['/unsupportedregion', '/', '/family/signup/'].includes(location.pathname)
        ) {
            logout('unsupportedregion');
        }
    }, [user, location]);

    useEffect(() => {
        if (user) client('api/business-type-translations/').then(setBusinessTypeTranslations).catch(consoleLogInDev);
    }, [user]);

    function initMixPanel(user: UserContextUser) {
        mixpanel.identify(user.id.toString());
        mixpanel.people.set({
            $name: user.first_name + ' ' + user.last_name,
            $email: user.email,
            $payment_setup: user.customer_id !== null || user.payment_information.length > 0,
            $payment_setup_verified: !user.payment_information.some((x) => x.status === 'NEW' && x.is_default),
            $points: user.points,
        });
    }

    function logout(redirect?: string) {
        if (!!localStorage.getItem('token')) client('api/auth/logout/', { method: 'POST' });
        const businessType = user?.businesses?.[0]?.business_type;
        const defaultRedirect = businessType === 'ELDERCARE' ? '/facilities' : '/';
        redirect = redirect || defaultRedirect;
        localStorage.removeItem('token');
        localStorage.removeItem('role');
        setUser(undefined);
        setRole(undefined);
        setHasPostedJob(false);
        setSavedProviders([]);
        history.push(redirect);
    }

    async function fetchUser() {
        let apiUser;
        try {
            const response = await client('api/users/identity/');
            apiUser = response;
            setUser(apiUser);
            setHasPostedJob(apiUser.has_posted_job);
            initMixPanel(apiUser);
        } catch (error) {
            localStorage.removeItem('token');
            window.location.reload();
        }
        return apiUser;
    }

    async function refreshUser(onComplete?: () => void) {
        try {
            const apiUser = await fetchUser();
            updateRole(apiUser, true);
            if (!apiUser) {
                localStorage.removeItem('token');
                window.location.reload();
            } else if (history.location.pathname.includes('send-funds')) {
                return;
            } else if (apiUser.hasOwnProperty('sitter') && apiUser.sitter.length) {
                setRole('sitter');
            } else if (apiUser.hasOwnProperty('businesses_active') && apiUser?.businesses_active?.length) {
                setRole('business_active');
                trackLoginActivity('BUSINESS');
            } else if (apiUser.hasOwnProperty('businesses') && apiUser?.businesses?.length) {
                setRole('business');
                trackLoginActivity('BUSINESS');
            } else if (apiUser.hasOwnProperty('family') && apiUser.family.length) {
                setRole('family');
                trackLoginActivity('FAMILY');
            } else if (apiUser.hasOwnProperty('is_superuser') && apiUser.is_superuser) {
                setRole('admin');
            } else if (apiUser.hasOwnProperty('has_outreach_permissions') && apiUser.has_outreach_permissions) {
                setRole('marketer');
            } else {
                localStorage.clear();
                history.push('/');
            }
            if (onComplete) {
                onComplete();
            }
            return apiUser;
        } catch (error) {
            localStorage.removeItem('token');
            window.location.reload();
        }
    }

    function updateHasPostedJob() {
        setHasPostedJob(true);
    }

    function updateRole(apiUser: any, trackLogin = false) {
        if (!apiUser) {
            setRole(undefined);
        } else if (apiUser.hasOwnProperty('sitter') && apiUser.sitter.length) {
            setRole('sitter');
        } else if (apiUser.hasOwnProperty('businesses_active') && apiUser?.businesses_active?.length) {
            setRole('business_active');
            if (trackLogin) {
                trackLoginActivity('BUSINESS');
            }
        } else if (apiUser.hasOwnProperty('businesses') && apiUser?.businesses?.length) {
            setRole('business');
            if (trackLogin) {
                trackLoginActivity('BUSINESS');
            }
        } else if (apiUser.hasOwnProperty('family') && apiUser.family.length) {
            setRole('family');
            if (trackLogin) {
                trackLoginActivity('FAMILY');
            }
        } else if (apiUser.hasOwnProperty('is_superuser') && apiUser.is_superuser) {
            setRole('admin');
        }
    }

    async function refreshAlreadyLoggedInUser(onComplete?: () => void) {
        const apiUser = await fetchUser();
        updateRole(apiUser);
        if (onComplete) {
            onComplete();
        }
    }

    function trackLoginActivity(userRole: string) {
        client('api/login-activity/', {
            method: 'POST',
            body: {
                user_role: userRole,
                location: window.location.href,
            },
        });
    }

    function updateSavedProviders(providerId: number) {
        let providerAlreadySaved = savedProviders.includes(providerId);
        if (providerAlreadySaved) {
            setSavedProviders(savedProviders.filter((id) => id !== providerId));
        } else {
            setSavedProviders([...savedProviders, providerId]);
        }
    }

    function getDisplayText(key: string, fallbackValue?: string) {
        let text = null;
        if (businessTypeTranslations && businessTypeTranslations[key]) {
            text = businessTypeTranslations[key];
        }
        return text ?? fallbackValue ?? key;
    }

    const contextValue = {
        user,
        tempToken,
        setTempToken,
        role,
        hasPostedJob,
        refreshUser,
        updateHasPostedJob,
        refreshAlreadyLoggedInUser,
        savedProviders,
        updateSavedProviders,
        fetchUser,
        getDisplayText,
        logout,
    };

    return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>;
}

export function useUserContext() {
    const context = useContext(UserContext);
    if (context === undefined) {
        throw new Error(
            'useUserContext must be used within a UserProvider. Wrap a parent component in <UserProvider> to fix this error.',
        );
    }
    return context;
}
